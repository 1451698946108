@import '../../assets/scss/variables';

.tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 23px 15px 20px;
  border: none;
  z-index: 1001;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
  border-radius: 11px;

  &__close {
    display: flex;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: -20px;
    z-index: 1;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;

    img {
      transform: rotate(45deg);
    }
  }

  &__title {
    position: relative;
    padding-bottom: 10px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      border-bottom: 1px solid $gray;
    }
  }

  &__content {
    font-weight: 300;
    font-size: 14px;
    margin-top: 12px;
    line-height: 16px;
  }
}

.tooltipOverlay {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
@media (max-width: $screen-tablet) {
}

@media (max-width: $screen-mobile) {
  .tooltip {
    width: 334px;

    &__title {
      font-size: 15px;
      line-height: 18px;
    }

    &__content {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
@media (max-width: 374px) {
  .tooltip {
    width: 300px;
    left: 20px !important;
  }
}
