@import '../../../assets/scss/variables';

.panel {
  padding: 24px 35px;
  margin-top: 10px;
  border: none;
  border-radius: 11px;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
}

.tooltip {
  height: 15px;
  width: 15px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 15px;
  align-self: flex-end;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 20px;
  right: 20px;
  visibility: hidden;
}

.teamRiskFactors {
  min-width: 40%;
  flex-grow: 1;
  position: relative;

  &__title {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10px 0;

    &Icon {
      align-self: center;
      min-width: 26px;
      min-height: 26px;
      margin-left: 23px;
    }

    &Title {
      margin-left: 66px;
      text-transform: capitalize;
      font-size: 15px;
      line-height: 18px;
    }
  }
}

@media (max-width: 1199px) {
  .teamRiskFactors {
    &__item {
      &Title {
        margin-left: 30px;
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .panel {
    padding: 17px 20px;
  }

  .teamRiskFactors {
    &__title {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    &__item {
      margin-left: 20px;

      &Title {
        font-size: 15px;
        line-height: 18px;
        margin-left: 60px;
      }
    }
  }
}
