@import '../../../assets/scss/variables';

.recommendationCategory {
  display: flex;
  flex-direction: column;
  padding: 18px;
  position: relative;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      left: 28px;
      bottom: 0;
      width: 90%;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
    }
  }
  &__nameTrend {
    display: flex;
  }

  &__nameTrend_open {
    display: grid;
    max-width: 100%;
    grid-template-columns: 2fr 6fr;
  }

  &__nameTrend_close {
    max-width: 50%;
  }

  &__name {
    text-transform: capitalize;
    flex-grow: 1;
    //max-width: 50%;
    align-self: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  &__trend {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    flex-grow: 1;
    //max-width: 50%;

    &Arrow {
      display: inline-block;
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-right: 22px;

      &.high {
      }
      &.equal {
        transform: rotate(90deg);
      }
      &.low {
        transform: rotate(180deg);
      }
      &.inconsistent {
        &.high {
          margin-right: -10px;
        }
        &.low {
          margin-right: 0;
        }
      }
      &Cont {
        display: flex;
      }
    }
  }

  &__keys {
    display: flex;
    overflow: hidden;
    max-height: 0;
    flex-wrap: wrap;

    &.active {
      max-height: 500px;

      &.noWorkload {
        display: flex;
        justify-content: center;
        padding: 16px;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
      }
    }
  }
  &__keysItem {
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex-grow: 1;

    min-width: 24%;
    max-width: 33%;
  }
  &__value {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    color: #f2f2f2;
  }
  &__key {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #f2f2f2;
    opacity: 0.5;
    margin-top: 2px;
  }
}

@media (max-width: 1600px) {
  .recommendationCategory {
    &__nameTrend_open {
      grid-template-columns: 2fr 3.8fr;
    }
  }
}

@media (max-width: 1141px) {
  .recommendationCategory {
    &__nameTrend_open {
      grid-template-columns: 2fr 3.6fr;
    }
  }
}

@media (max-width: $screen-tablet) {
  .recommendationCategory {
    padding: 18px 0;
    font-size: 14px;

    &:not(:last-child) {
      &:after {
        left: 15px;
        width: 95%;
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .recommendationCategory {
    padding: 17px 0;
    font-size: 14px;

    &__name {
      flex: 33%;
      max-width: 50%;
      min-width: 152px;
      font-size: 12px;
      line-height: 14px;
    }

    &__nameTrend_open {
      grid-template-columns: 1fr 0.9fr;
    }

    &__trend {
      margin: 0;
      font-size: 12px;
      line-height: 14px;
      flex-grow: 1;
      //max-width: 50%;

      &Arrow {
        margin-right: 11px;
      }
    }

    &__keys {
      flex-direction: column;

      &.active {
        &.noWorkload {
          font-size: 12px;
          line-height: 14px;
          padding-bottom: 0;
        }
      }
    }

    &__keysItem {
      flex-direction: row;
      max-width: 100%;
      align-items: center;
      padding: 6px 2px 6px 2px;

      &:first-child {
        padding-top: 14px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__value {
      font-size: 20px;
      flex-grow: 1;
      max-width: 50%;
    }

    &__key {
      flex-grow: 1;
      max-width: 50%;
      font-size: 12px;
      padding-left: 20px;
    }

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: unset;
        right: 0;
        width: 95%;
        min-width: 284px;
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
      }
    }
  }
}

@media (max-width: 374px) {
  .recommendationCategory {
    &__trend {
      margin: 0;
    }
    &__name {
      min-width: 112px;
    }
    &:not(:last-child) {
      &:after {
        min-width: 100%;
      }
    }
  }
}
