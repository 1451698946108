@import '../../../assets/scss/variables';

.center {
  text-align: center;
  color: #cdd2d2;
}
.errorCode {
  font-size: 170px;
  margin-bottom: 20px;
}
.errorDesc {
  font-weight: 600;
}