@import '../../assets/scss/variables';

.dropdown{
  width: 260px;
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  // padding: 25px 0;

  &.open{
    background: #191A1E;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    .item{
      position: relative;
      &::after{
        content: '';
        width: 260px;
        height: 1px;    
        background: rgba(255, 255, 255, 0.25);
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:last-child::after{
        display: none;
      }
    }
    
  }

  .item{
    padding-left: 15px;
    display: flex;
    align-items: center;
    height: 83px;
    position: relative;
   
    &.btn{
      padding: 0 20px;
    }

    .logo{
      width: 50px;
      height: 50px;
      object-fit: fill;
    }

    .update{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 220px;
      height: 48px;
      background: linear-gradient(180deg, #2C2F35 0%, #1F2326 100%);
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
      border-radius: 84px;
      cursor: pointer;

      img{
        width: 24px;  
        height: 24px;
        margin-right: 12px;
      }
    }

    .content{
      margin-left: 25px;
      .name{
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        img{
          width: 10px;
          height: 10px;
          transform: rotate(-90deg);
          cursor: pointer;
          margin-left: 10px;
          object-fit: cover;
        }
      }

      .lastUpdate{
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        opacity: 0.5;

        &.error{
          display: flex;
          color: #B03B2A;
          opacity: 1;
        }
      }
    }
  }

  &__wrap{
    display: none;

    &.open{
      display: flex;
      flex-direction: column;
    }
  }
}

.dropdownOverlay{

}

// .dropdown {
//   position: absolute;
//   bottom: 100px;
//   left: 30px;
//   display: flex;
//   flex-direction: column;
//   width: 260px;
//   border: none;
//   border-radius: 11px;
//   background-color: #191a1e;
//   z-index: 1001;
//   box-shadow: 0 5px 3px rgba(0, 0, 0, 0.2);

//   &__item {
//     padding: 18px 23px;
//     height: 54px;
//     & a, Link{
//       display: flex;
//       align-items: center;

//       & img{
//         width: 24px;
//         height: 24px;
//         object-fit: none;
//         margin-right: 10px;
//       }
//     }

//     & a {
//       color: $white;
//       opacity: 0.5;
//       transition: 0.3s ease-in-out;
//       text-transform: capitalize;
//       font-size: 15px;
//       line-height: 18px;

//       &:hover {
//         opacity: 1;
//         color: white;
//         cursor: pointer;
//         text-decoration: none;
//         // background-color: #26292E;
//       }
//     }

//     &:not(:last-child) {
//       position: relative;

//       &:after {
//         content: '';
//         position: absolute;
//         left: 50%;
//         bottom: 0;
//         transform: translateX(-50%);
//         width: 200px;
//         border-bottom: 1px solid $black40;
//       }
//     }
//   }
// }

// .dropdownOverlay {
//   background-color: rgba(0, 0, 0, 0);
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   z-index: 1000;
// }

@media (max-width: $screen-tablet) {
}

@media (max-width: $screen-mobile) {
  .dropdown {
    margin-left: 15px;
  }
}
