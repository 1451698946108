@import '../../assets/scss/variables';

.dropdown {
  // position: absolute;
  // bottom: 100px;
  // left: 30px;
  display: flex;
  flex-direction: column;
  width: 260px;
  border: none;
  border-radius: 11px;
  background-color: #191a1e;
  z-index: 1001;
  box-shadow: 0 5px 3px rgba(0, 0, 0, 0.2);

  &__item {
    padding: 18px 23px;
    height: 54px;
    & a, Link{
      display: flex;
      align-items: center;

      & img{
        width: 22px;
        height: 22px;
        object-fit: contain;
        margin-right: 10px;
      }
    }

    & a {
      color: $white;
      opacity: 0.5;
      transition: 0.3s ease-in-out;
      text-transform: capitalize;
      font-size: 15px;
      line-height: 18px;

      &:hover {
        opacity: 1;
        color: white;
        cursor: pointer;
        text-decoration: none;
        // background-color: #26292E;
      }
    }

    &:not(:last-child) {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 200px;
        border-bottom: 1px solid $black40;
      }
    }
  }
}

.dropdownOverlay {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 62px;
  bottom: 0;
  left: 30px;
  right: 0;
  z-index: 1000;
}

@media (max-width: $screen-tablet) {
}

@media (max-width: $screen-mobile) {
  .dropdown {
    left: 25px;
    height: auto;
  }
}
