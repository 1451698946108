@import '../../../assets/scss/variables';

.panel {
  padding: 20px 20px 20px 25px;
  border: none;
  border-radius: 11px;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}

.player__tooltip {
  display: inline-block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 16px;
}

.player__noAdjustmentsTitle {
  text-align: center;
}

.playerInfo {
  display: flex;
  flex-grow: 1;
  min-width: 50%;
  max-width: 100%;
  border-radius: 11px;

  &__list {
    display: flex;
    align-items: center;

    & .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-left: 10px;
      margin-right: 10px;

      &.high {
        background-color: $high;
      }

      &.medium {
        background-color: #c66f29;
      }

      &.contained {
        background-color: $yellowhigh;
      }

      &.low {
        background-color: $low;
      }

      &.injured {
        background-color: $grayinjured;
      }
    }
  }

  &.injured,
  &.noAdjustments {
    width: 100%;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);

    .panel {
      margin-top: 0;
      box-shadow: none;
    }

    & .playerInfo__list {
      margin-top: 4px;
    }
  }
}

.player__injuredTitle {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

.player__injured,
.player__noAdjustments {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;

  &Icon {
    align-self: center;
    width: 58px;
    height: 58px;
    margin: 22px;
  }

  & .player__tooltip {
    align-self: flex-end;
    z-index: 1;
  }
}

.player__details {
  display: flex;
  align-items: center;
  min-height: 170px;
  min-width: 45%;

  flex-grow: 1;
  position: relative;

  & .player__tooltip {
    position: absolute;
    right: 18px;
    top: 18px;
    z-index: 1;
  }
}

.injured,
.noAdjustments {
  .player__details {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.playerInfo .dot,
.player__level {
  &.high {
    color: $high;
  }

  &.medium {
    color: #c66f29;
  }

  &.contained {
    color: $yellowhigh;
  }

  &.low {
    color: $low;
  }

  &.injured {
    color: $grayinjured;
  }
}

.player__image {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }

  &Initials {
    color: $white;
    text-align: center;
    padding-top: 4px;
    background-color: $gray;
    font-size: 24px;
    font-weight: lighter;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    width: 100%;
    height: 100%;
    line-height: 1.6;

    &.high {
      border-color: $high;
    }

    &.medium {
      border-color: #c66f29;
    }

    &.contained {
      border-color: $yellowhigh;
    }

    &.low {
      border-color: $low;
    }

    &.injured {
      border-color: $grayinjured;
    }
  }
}

.player__level {
  text-transform: capitalize;
  margin-left: 30px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #49a0df;
}

.player__detected {
  color: $white;
  margin-left: 30px;
  font-size: 15px;
  line-height: 18px;
  min-width: 240px;
  margin-top: 6px;
}

.additionalInfo {
  min-width: 50%;
  padding: 20px;
  width: 100%;
}

.injured,
.noAdjustments {
  .additionalInfo {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.player__tooltip {
  display: inline-block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 16px;
}

@media (max-width: 1170px) {
  .panel {
    padding-left: 15px;
  }
}

@media (max-width: $screen-tablet) {
  .player__detected {
    min-width: 160px;
  }

  .additionalInfo {
    padding-left: 0;
  }

  .player__level,
  .player__detected {
    margin-left: 15px;
  }
}

@media (max-width: $screen-mobile) {
  .player__tooltip {
    margin-left: 0;
    margin-right: 10px;
  }
  .playerInfo {
    flex-wrap: wrap;

    &__list {
      margin-top: 0;
    }

    & .player__details {
      padding-top: 33px;
      padding-bottom: 33px;
      padding-left: 6px;
    }
    &.noAdjustments,
    &.injured {
      box-shadow: none;
    }
  }

  .player__details {
    flex-direction: row;
    width: 100%;
    min-height: unset;
    position: relative;

    & .player__tooltip {
      align-self: flex-start;
      order: 5;
      position: absolute;
      top: 16px;
      right: 16px;
      margin-left: 0;
      margin-right: 0px;
    }
  }

  .injured,
  .noAdjustments {
    .additionalInfo {
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
      border-bottom-right-radius: 11px;
      border-top-right-radius: 11px;
      padding-left: 20px;

      &.panel {
        margin-top: 14px;
        box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
      }
    }

    .player__details {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .player__level {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-left: 24px;
  }

  .player__detected {
    font-size: 15px;
    line-height: 18px;
    margin-left: 24px;
  }

  .player__injured,
  .player__noAdjustments {
    font-size: 14px;
    position: relative;
    padding-top: 0;

    &Icon {
      height: 46px;
      margin-bottom: 0;
    }

    & .player__tooltip {
      position: absolute;
      top: -137px;
      margin-left: 0;
      margin-right: -4px;
    }
  }
}
