@import '../../../assets/scss/variables';

.panel {
  padding: 24px 35px;
  margin-top: 10px;
  border: none;
  border-radius: 11px;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
}

.playerSummary {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  min-width: calc(60% - 10px);

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
  }

  &__items {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    padding: 10px;

    .playerSummaryItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__chart {
      }

      &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__percent {
        font-size: 24px;
        font-weight: 300;

        &.low_count {
          color: $low;
        }

        &.contained_count {
          color: #e0c222;
        }

        &.risk_count {
          color: $high;
        }

        &.injured_count {
          color: $grayinjured;
        }
      }

      &__title {
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        text-align: center;

        &.low_count {
          color: $low;
        }

        &.contained_count {
          color: #e0c222;
        }

        &.risk_count {
          color: $high;
        }

        &.injured_count {
          color: $grayinjured;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .playerSummary {
    &__items {
      flex-wrap: wrap;

      .playerSummaryItem {
        flex: 50%;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .panel {
    padding: 17px 20px;
  }

  .playerSummary {
    margin-left: 0;

    &__title {
      font-size: 15px;
      padding-bottom: 10px;
    }

    &__items {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;

      .playerSummaryItem {
        flex-direction: row;
        min-width: 50%;
        padding-top: 10px;
        padding-bottom: 0;
        margin-bottom: 0;

        &__title {
          font-size: 12px;
          line-height: 14px;
          text-align: left;
        }

        &__description {
          margin-left: 16px;
          align-items: flex-start;
        }
      }
    }
  }
}
