@import '../../../assets/scss/variables';

.playerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

@media (max-width: $screen-tablet) {
  .playerContainer {
    //padding: 30px 0;
  }
}

@media (max-width: $screen-mobile) {
  .playerContainer {
    padding: 0;
  }
}
