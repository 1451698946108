@import '../../../assets/scss/variables';

.panel {
  padding: 31px 35px;
  margin-top: 10px;
  border: none;
  border-radius: 11px;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: $screen-mobile) {
  .panel {
    padding: 17px 20px;
  }
}
