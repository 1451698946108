@import '../../assets/scss/variables';

.sidebar {
  position: relative;
  background: linear-gradient(180deg, #34393f 0%, #18191d 751.39%);
  box-shadow: 0 4px 12px -1px rgba(0, 0, 0, 0.25);
  width: 320px;
  transition: 0.3s ease-in-out;
  padding-top: 20px;
  height: 100%;

  .sidebar__logo {
    margin-left: 50px;
    margin-bottom: 17px;
    width: 187px;
    //height: 40px;
  }

  .sidebar__item {
    &-title {
      margin-left: 20px;
    }
  }

  &__toggle {
    display: none;
    position: fixed;
    // margin-top: 27px;
    margin-left: 9%;
    z-index: 10001;
    background: transparent;
    cursor: pointer;
    border: none;
    transform: rotate(180deg);
    transition: 0.3s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__logo {
    &-image {
      &--mobile {
        display: none;
      }
    }
  }

  &__user {
    margin-top: 35px;
    display: flex;
    align-items: center;
    transition: 0.3s ease-in-out;
    padding-left: 48px;
    position: relative;

    &-content {
      display: flex;
      padding-left: 15px;
      padding-right: 10px;
      align-items: center;
      height: 50px;
    }

    &-wrapper {
      display: flex;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }

    &-name{
      overflow: hidden;
      white-space: nowrap;
      width: 130px;
      text-overflow: ellipsis;
      margin-right: 8px;
    } 

    &-dropdown {
      margin-left: auto;
      display: inline-flex;
      opacity: 0.6;
      transition: 0.3s ease-in-out;
      cursor: pointer;

      img {
        transform: rotate(-90deg);
        cursor: pointer;
      }

      &--open {
        opacity: 1;
        img {
          transform: rotate(90deg);
        }
      }

      
    }

    &-avatar {
      border-radius: 50%;
      width: 50px;
      min-width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
      color: #202020;
    }
  }

  &__content {
    margin-top: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-menu{
      width: 100%;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    opacity: 0.5;
    transition: 0.3s ease-in-out;
    padding-left: 50px;

    &-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      transition: 0.3s ease-in-out;
    }

    &--active {
      background: #26292e;
      opacity: 1;

      a {
        font-weight: 500;
      }
    }

    a {
      
      width: 100%;
      height: 80px;
      justify-content: center;
      color: #ffffff;
      font-size: 15px;
      line-height: 18px;
    }

    &-img-wrapper {
      width: 40px;
      height: 40px;

      img{
        object-fit: none;
        height: 100%;
      }

    }

    &-title {
      // max-width: 0;
      // padding-left: 0;
      // overflow: hidden;
      // white-space: nowrap;
      // transition: 0.3s ease-in-out;
    }
  }
}


@media (max-width: $screen-mobile) {
  .sidebar {
    width: 306px;

    .sidebar__logo {
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-image {
        width: 215px;
        transition: 0.3s ease-in-out;

        &--mobile {
          display: unset;
          height: 100%;
          width: auto;
          min-height: 100%;
        }

        &--desktop {
          display: none;
        }
      }
    }

    &__item {
      padding-left: 0;
    }

    .sidebar__user {
      padding-left: 25px;

      &-wrapper {
        width: 100%;
      }

      &-content {
        max-width: 100%;
        width: 100%;
        padding-left: 26px;
        transition: max-width 0.3s ease-in-out;
        padding-right: 30px;
      }
    }

    .sidebar__item {
      &-wrapper {
        width: 100%;
        padding-left: 35px;
      }

      &-title {
        max-width: 145px;
        padding-left: 36px;
        transition: max-width 0.3s ease-in-out;
      }

      a {
        height: 72px;
      }
    }

    &--open{
      .sidebar__toggle {
        transform: rotate(360deg);
      }
    }

    &__toggle {
      display: block;
      top: 40px;
      left: 0;
      margin-left: 4%;
    }

    &__content {
      margin-top: 35px;
    }

    &__user {
      margin-top: 30px;
    }
  }
}

@media (max-width: 374px) {
  .sidebar {
    width: 260px;
  }
}
