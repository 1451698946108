.select {
  // width: 169px;
  // height: 48px;
  &__label{
    color: white;
    margin-left: 25px;
    font-size: 12px;
  }
}

.react-select-container {
  max-width: 300px;
  background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
  border-radius: 84px;
}

.react-select {
  &__control {
    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
  }
  &__multi-value{
    background-color: rgb(243, 28, 28);
  }
}


// input[type="checkbox"] {
//   appearance: none;
//   //background-color: #fff;
//   margin: 0;
//   font: inherit;
//   color: red;
//   width: 16px;
//   height: 16px;
//   border: 0.15em solid white;
//   border-radius: 0.15em;
//   transform: translateY(-0.075em);
//   // display: flex;
//   // place-content: center;
// }

// input[type="checkbox"]::before {
//   content: "";
//   width: 0.65em;
//   height: 0.65em;
//   transform: scale(0);
//   transition: 120ms transform ease-in-out;
//   box-shadow: inset 1em 1em red;
//   transform-origin: bottom left;
//   clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
// }

// input[type="checkbox"]:focus {
//   outline: max(2px, 5px) solid red;
//   outline-offset: max(2px, 5px);
// }