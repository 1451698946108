@import '../../assets/scss/variables';


.searchBar{
  position: relative;
  width: 250px;
  height: 56px;
}

.inputContainer {
  width: 250px;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  //background: #191A1E;
  background: linear-gradient(180deg, #2C2F35 0%, #1F2326 100%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  position: absolute;
}

.collapsed {}

.input {
  height: 47px;
  padding-left: 15px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  background-color: transparent;
  border-width: 0;
  padding-right: 12px;
  cursor: pointer;
  z-index: 9;
  color: hsl(0, 0%, 50%);
}

.input_collapsed {
  padding-left: 56px;
}

.hint {
  position: absolute;
  height: 18px;
  z-index: 1;
  padding-top: 1px;
  padding-left: 1px;
  color: #FFFFFF;
  opacity: 0.5
}

.listContainer {
  width: 250px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  //background: #191A1E;
  background: linear-gradient(180deg, #2C2F35 0%, #1F2326 100%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  position: absolute;



  &__searchContainer {
    max-height: 190px;
    overflow: overlay;
    background: transparent;
    width: 100%;

  }

  &__search {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__noResults{
    width: 100%;
  }


  &__line {
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    width: 100%;
  }

  &__line_collapsed {
    width: 380px;
  }

  &__playerListItem {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    opacity: 0.75;
    padding-top: 10px;
    padding-left: 11px;
    width:  100%;
    padding-bottom: 10px;
    height: 54px;
    display: flex;
    align-items: center;
  }

  &__playerListItemHover {
    background: #26292E;
  }

}

.icon {
  width: 18px;
  height: 18px;
  margin-left: 23px;
}

.player {
  display: flex;
  align-items: center;

  &__image {
    margin-right: 12px;
    border-radius: 50%;
    padding: 1px;
    background: white;

    img {
      width: 30px;
      height: 30px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
    }
  }
  &__imageInitials {
    width: 30px;
    height: 30px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


@media (max-width: 1500px) {
  .inputContainer {
    //margin-left: 22%;
  }
  .listContainer {
    //margin-left: 22%;
  }

  .collapsed {
    margin-left: 20.5%;
    width: 404px;
  }

  .icon {
    &__collapsed {
      margin-right: 12%;
    }
  }

}

@media (max-width: $screen-mobile) {

  .searchBar{
    display: none;
  }

}