@import '../../../assets/scss/variables';

.accordion {
  cursor: pointer;
  border: none;
  max-height: 30px;

  &__arrow {
    width: 30px;

    &__up{
      transform: rotate(90deg);
    }
    &__down{
      transform: rotate(-90deg);
    }
  }
}

.panel {
  padding: 26px 38px 0;
  border: none;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
  position: relative;
  margin-top: 10px;
}

.panel.active {
  max-height: 2500px;
}

.player__tooltip {
  display: inline-block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 16px;
}

.riskManagement {
  //background: linear-gradient(180deg, #2C2F35 0%, #2F3539 100%);
  display: flex;

  &Micro {
    display: flex;
    flex-direction: column;
  }

  &__radio {
    width: calc(100% - 40px);
    //margin-left: 20px;
    background: linear-gradient(90deg, rgba(52, 44, 53, 0) 0%, rgba(47, 53, 57, 0.45) 100%);
    box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.4);
    border-radius: 22px;
    height: 44px;
    margin-top: 50px;

    &Buttons {
      display: flex;
      justify-content: space-between;
      padding: 6px;
    }
    &Button {
      display: flex;
      flex: 1;

      &Label {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        //top: -34px;
        top: -50px;
        //left: -16px;
        left: -8px;

        font-size: 12px;
        font-weight: 500;
      }
      &Checkmark {
      }
      &Input {
        margin-top: 24px;
        display: none;
      }

      &Label {
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-align: center;
        &.enabled {
          cursor: pointer;
        }
      }

      &Checkmark {
        position: absolute;
        top: 34px;
        //left: 34px;
        height: 32px;
        width: 32px;
        background-color: #2d3036;
        border-radius: 50%;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      }

      /* When the radio button is checked, add a blue background */
      &Label input:checked ~ &Checkmark {
        background-color: #2d3036;
        filter: drop-shadow(0px 0px 3px #ffffff);
      }
      /* Create the indicator (the dot/circle - hidden when not checked) */
      &Checkmark:after {
        content: '';
        position: absolute;
      }
      /* Style the indicator (dot/circle) */
      &Label &Checkmark {
        &.back_to_safe:after,
        &.low:after {
          background: linear-gradient(180deg, #7492c9 0%, #49a0df 100%);
        }
        &.contained:after {
          background: linear-gradient(180deg, #ecd858 0%, #e0d35a 100%);
        }
        &.risk_no_adjustments:after {
          background: linear-gradient(180deg, #c66f29 0%, #cb8f29 100%);
        }
        &.risk_adjustments:after {
          background: linear-gradient(180deg, #a9132a 0%, #9e4b20 100%);
        }
        &.disabled:after {
          background: #575757;
        }
        &:after {
          top: 5px;
          left: 5px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
        }
      }

      &RiskDetected input ~ .riskManagement__radioButtonCheckmark {
        border: 1px solid $high;
      }

      &RiskDetected input ~ .riskManagement__radioButtonCheckmark:after {
        top: 4px;
        left: 4px;
      }
      &Rect {
        visibility: hidden;
      }
    }
  }

  &__riskDetected {
    margin-top: 16px;
    margin-bottom: 34px;
    background: -webkit-linear-gradient(#bb2628, #b03b2a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 500;
    min-height: 22px;
    margin-left: 3%;
  }

  &__title {
    display: flex;
    align-items: center;

    &Text {
      min-width: 170px;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
    }
  }

  &__recommendations {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-top: -10px;

    &.showRecommendations {
      width: 100%;
    }
  }
  &__completeRest {
    width: 100%;
    min-height: 300px;
    align-items: center;
    justify-content: center;
  }

  &__prediction {
    display: flex;
    flex-direction: column;
    min-width: 230px;
    margin-top: 20px;

    &Title {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 12px;
    }
    &Body {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
    }
    &Outcome {
      display: flex;
      flex-direction: column;
      &Title {
        width: 160px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
        margin-right: 20px;
      }
    }
    &Buttons {
      display: flex;
      justify-content: center;
      height: 55px;
      margin-bottom: 14px;
      background: linear-gradient(90deg, rgba(52, 44, 53, 0) 0%, rgba(47, 53, 57, 0.45) 100%);
      box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.48);
      border-radius: 22px;
      align-items: center;
      padding: 5px 20px;
      
    }
    &Button {
      display: flex;
      width: 95px;
      height: 26px;
      border-radius: 13px;
      font-size: 14px;
      font-weight: 300;
      justify-content: center;
      align-items: center;
      &.back_to_safe,
      &.low {
        background: linear-gradient(180deg, #7492c9 0%, #49a0df 100%);
      }
      &.contained {
        background: #f5ba00;
      }
      &.risk_no_adjustments {
        background: linear-gradient(180deg, #c66f29 0%, #cb8f29 100%);
      }
      &.risk_adjustments {
        background: linear-gradient(180deg, #9e4b20 0%, #a9132a 100%);
      }
    }
    &Arrow {
      width: 16px;
      height: 18px;
    }
  }
}

.high {
  background: linear-gradient(180deg, #9e4b20 0%, #a9132a 100%);
}

.contained_risk {
  background: #f5ba00;
}

@media (min-width: 1440px) {
  .riskManagement__recommendations {
    &.alignTop {
      margin-top: 21px;
    }

    &.alignBottom {
      margin-top: -2px;
    }
  }
}
@media (max-width: 1440px) {
  .riskManagement__riskDetected {
    margin-left: 0;
  }
}
@media (max-width: 1230px) {
  .riskManagement__radioButtonLabelText {
    margin-top: -10px;
  }
}
@media (max-width: $screen-tablet) {
  .riskManagement {
    flex-direction: column;
    position: relative;

    &__recommendations {
      &.showRecommendations {
        width: 100%;
        margin-left: 0;
      }
    }

    & .accordion {
      position: absolute;
      top: 18px;
      right: 18px;
    }
    &__prediction {
      &Title {
        max-width: 178px;
      }
      &Body {
        flex-direction: row;
        margin-left: 0;
      }
      &Outcome {
        flex-direction: column;
      }
      &Outcome + &Outcome {
        margin-left: 28px;
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .panel {
    padding: 18px 20px 0;
  }
  .riskManagement {
    flex-direction: column;
    position: relative;

    &__radio {
      &Button {
        &Label {
          top: -34px;
          left: -16px;
        }
      }
    }

    &__title {
      &Text {
        font-size: 15px;
        line-height: 18px;
      }
    }

    &__completeRest {
      min-height: 134px;
    }

    &__recommendations {
      margin-left: 0;
    }

    .accordion {
      order: -1;
      position: absolute;
      top: -6px;
      right: 40px;
    }
    &Micro {
      & .accordion.micro_cycle {
        top: -38px;
        right: 0px;
      }
    }
    &__prediction {
      flex-direction: column;

      &Title {
        max-width: 180px;
      }

      &Outcome {
        flex-direction: column;
        align-items: center;

        &Title {
          // margin-left: -34px;
        }
      }

      &Outcome + &Outcome {
        margin-left: 0;
      }

      &Body {
        flex-direction: column;
        margin-left: 0;
      }

      &Buttons {
        flex-direction: row;
        width: 100%;
      }

      &Button {
        width: 95px;
        font-size: 15px;
      }
    }
    &__radio {
      margin-left: 20px;
    }
    &__radioButtons {
      width: 100%;
      margin-left: 20px;
    }

    &__arrows {
      display: flex;
    }
    &__radioButtonArrow {
      display: flex;
      width: 17px;
      height: 17px;
      //margin-top: 13px;
      margin-left: -16px;
      cursor: pointer;
      background: #34393f;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 0.5);
    }

    &__radioButtonArrow_left {
      position: absolute;
      transform: rotate(180deg);

      left: 63px;
    }

    &__radioButtonRect {
      margin-top: -10px;
      display: flex;
      width: 20px;
      height: 65px;
      background: #2d3036;
      align-items: center;
      justify-content: center;
    }
    &__head.micro_cycle {
      order: 2;
    }
    &__riskDetected {
      margin-top: 7px;
      margin-left: 15%;
    }
  }

  .player__tooltip {
    position: absolute;
    right: -4px;
    top: 2px;
  }
  .riskManagementMicro .player__tooltip.micro_cycle {
    right: 15px;
    top: 20px;
  }
}

@media (max-width: 450px) {
  .riskManagement__radioButtonLabelText {
    margin-top: -10px;
  }
  .riskManagement__radioButtonLabel {
    text-align: left;
    align-items: flex-start;
  }
  .riskManagement__radio {
    margin-left: 9px;
    width: 100%;
  }
  .riskManagement__radioButtonCheckmark {
    left: 5px;
  }
  .riskManagement__radioButtonArrow {
    //left: 60px!important;
  }
  .riskManagement__radioButtonArrow_left {
    left: 53px;
  }
  .riskManagement__riskDetected {
    margin-left: 4%;
  }
}

@media (max-width: 400px) {
  .riskManagement__radioButtonArrow_left {
    left: 52px;
  }
}
