@import '../../../assets/scss/variables';

.panel {
  padding: 26px 38px 26px;
  border: none;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
  position: relative;
  margin-top: 10px;
}

.player__tooltip {
  display: inline-block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 16px;
  position: absolute;
  top: 18px;
  right: 18px;
}

.bio.similar {
  margin-left: 10px;
}

.similar {
  //min-width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 48%;

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 128px;
  }

  &__title {
    display: flex;
    font-size: 15px;
    line-height: 18px;
    justify-content: space-between;
    padding: 0;
  }

  &__injuries {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;

    .injury {
      padding: 10px 10px 0 10px;

      &__name {
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;
      }

      &__severity {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        margin-top: 1px;
      }

      &.hi {
        color: #da2a16;
      }

      &.me {
        color: #c66f29;
      }

      &.co {
        color: #e0c222;
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .panel {
    padding: 17px 22px 19px 22px;
  }

  .bio.similar {
    margin-left: 0;
  }

  .similar {
    margin-left: 0;

    &__wrap {
      min-height: auto;
    }

    &__title {
      font-size: 14px;
    }

    &__injuries {
      margin-top: 20px;
    }

    .injury {
      padding-top: 0;

      &__name {
        font-size: 17px;
      }

      &__severity {
        font-size: 14px;
        opacity: 0.75;
      }
    }
  }
  .player__tooltip {
    margin-left: 10px;
    //margin-right: 10px;
    top: 16px;
    right: 16px;
  }
}
