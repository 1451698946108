@import '../../../assets/scss/variables';

.panel {
  padding: 30px 18px 14px 35px;
  margin-top: 10px;
  border: none;
  border-radius: 11px;
  background: linear-gradient(180deg, rgba(44, 47, 53, 0.5) 0%, rgba(47, 53, 57, 0.5) 100%);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 270px;
}

.riskTrend {
  padding-bottom: 0;

  &__wrap {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;
  }

  &__apexchart {
    width: 99%;
  }

  &__seeMore {
    position: absolute;
    left: 20%;
    top: -4px;
    display: flex;
    align-items: center;
    z-index: 1;

    &Date {
      font-size: 24px;
      font-weight: 300;

      &.high {
        color: $high;
      }

      &.medium {
        color: $medium;
      }

      &.low {
        color: $low;
      }

      &.injured {
        color: $grayinjured;
      }
    }

    &Text {
      padding-left: 15px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }
  }

  &Legend {
    display: flex;
    min-width: 320px;
    text-transform: capitalize;
    justify-content: flex-end;
    top: -12px;
    position: absolute;

    &__name {
      color: $white;
      font-size: 12px;
      line-height: 14px;
    }

    &__item {
      flex-grow: 1;

      &.dot {
        &.high {
          color: $high;
        }

        &.medium {
          color: $medium;
        }

        &.low {
          color: $low;
        }

        &.injured {
          color: $grayinjured;
        }
      }
    }
  }
}

@media (max-width: $screen-tablet) {
  .riskTrend {
    &__seeMore {
      left: 18%;
    }
  }
}

@media (max-width: $screen-mobile) {
  .noData {
    min-height: 390px;
  }
  .panel {
    padding: 17px 17px 15px 21px;
  }
  .riskTrend {
    &Legend {
      top: 24px;

      &__name {
        font-size: 14px;
      }
    }
    &__seeMore {
      display: none;
    }
  }
}

@media (max-width: 374px) {
  .riskTrend {
    &__wrap {
      justify-content: flex-start;
    }
    &Legend {
      min-width: 300px;
    }
  }
}
