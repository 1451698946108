@import '../../../assets/scss/variables';

.panel {
  padding: 26px 24px 23px 38px;
  margin-top: 10px;
  border: none;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
}

.player__tooltip {
  display: inline-block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 16px;
}

.bioRiskFactors {
  min-width: calc(50% - 10px);
  margin-left: 10px;
  justify-content: space-between;
  position: relative;

  & .player__tooltip {
    position: absolute;
    top: 14px;
    right: 14px;
    margin-left: 0;
  }

  & > .apexcharts-yaxis {
    & .apexcharts-text {
      fill: #ff00ff !important;
    }
  }
}

@media (max-width: $screen-mobile) {
  .bioRiskFactors {
    margin-left: 0;
  }
  .player__tooltip {
    margin-left: 0;
    margin-right: 10px;
  }
}
