@import '../../assets/scss/variables';

.dataContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 10px 45px 10px 64px;
}

.panel {
  padding: 51px 50px 51px 46px;
  border: none;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
}

.tooltip {
  height: 15px;
  width: 15px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 15px;
}

.team {
  display: flex;
  margin-bottom: 14px;

  &__wrap {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &__cont {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;

    & form {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }
  }

  &__title,
  &__lastUpdate {
    display: flex;
    flex-direction: column;
    margin-right: 76px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
  }

  &__titleName,
  &__lastUpdateDate {
    font-weight: 400;
  }

  &__icon {
    margin-right: 33px;

    img {
      width: 67px;
      height: 67px;
    }
  }

  &__update {
    display: flex;
    margin-left: auto;
    line-height: 16px;
    width: 204px;
    height: 36px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    color: $white;
    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 84px;
    cursor: pointer;

    &:active {
      background: #34393f;
    }
  }

  &__inProgress {
    min-width: 132px;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    text-align: center;
    margin-left: auto;
  }
}

.modalUpdate {
  &__close {
    padding: 0;
    width: 30px;
    height: 30px;
    background: transparent;
    transform: rotate(45deg);
    border: none;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    right: 0;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-bottom: 15px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    &Success {
      padding: 0 0 16px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    min-height: 100px;
    padding-top: 12px;
  }

  &__ok {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & img {
      width: 49px;
      height: 49px;
    }
  }

  &__description1,
  &__description2 {
    margin-bottom: 10px;
  }

  &__radioTitle {
    margin-bottom: 16px;
  }

  &__radioCont {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .container {
      display: flex;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      align-items: center;
      height: 20px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background: #2a2d33;
      box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background: #2a2d33;
      box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background: #2a2d33;
      box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 7px;
      left: 7px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #c4c4c4;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 84px;
    border: none;
    color: $white;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;

    &:active {
      background: #34393f;
    }
  }

  &__button + &__button {
    margin-left: 14px;
  }
}

.textTransformCapitalize {
  text-transform: capitalize;
}

@media (max-width: 1199px) {
  .panel {
    padding: 25px;
  }

  .team {
    &__title,
    &__lastUpdate {
      margin-right: 30px;
    }

    &__update {
      width: 150px;
    }
  }
}

@media (max-width: $screen-mobile) {
  .data {
    padding: 0 15px;
  }

  .panel {
    padding: 24px 25px 13px;
  }

  .team {
    &__cont {
      flex-direction: column;
      margin-left: 28px;

      & form {
        flex-direction: column;
        width: 100%;
      }
    }

    &__icon {
      margin-left: 0;
      align-self: flex-start;
      padding: 0;
      margin-right: 0;

      img {
        width: 40px;
        height: 40px;
      }
    }

    &__title,
    &__lastUpdate {
      flex-direction: row;
      font-size: 15px;
      line-height: 18px;
      flex-grow: 1;
      min-width: 100%;
    }

    &__lastUpdate {
      margin-top: 6px;
    }

    &__titleName,
    &__lastUpdateDate {
      font-weight: 300;
      padding-left: 4px;
      opacity: 1;
    }

    &__title,
    &__lastUpdate {
      margin-right: 0;
    }

    &__update {
      width: 103px;
      height: 31px;
      font-size: 12px;
      margin-top: 16px;
      justify-self: center;
      margin-right: auto;
      transform: translateX(-50%);
      margin-left: calc(50% - 28px);
      position: relative;
    }

    &__inProgress {
      min-width: 100%;
      padding-top: 20px;
      padding-bottom: 10px;
      margin-left: -70px;
      font-size: 12px;
    }
  }

  .tooltip {
    display: none;
  }

  .modalUpdate {
    padding: 0;

    &__title {
      font-size: 16px;

      &Success {
        padding: 0 0 10px 0;
      }
    }

    &__content {
      font-size: 14px;
    }

    &__button {
      min-height: 32px;
      min-width: 110px;
      font-size: 12px;
    }

    &__ok {
      & img {
        padding-top: 10px;
      }
    }
  }
}
