@import '../../assets/scss/variables';

.panel {
  padding: 14px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: $black32;
}

.tooltip {
  height: 15px;
  width: 15px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 15px;
  align-self: flex-end;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 20px;
  right: 20px;
  visibility: hidden;
}

.block1 {
  display: flex;
}

@media (max-width: $screen-tablet) {
}

@media (max-width: $screen-mobile) {
  .block1 {
    flex-direction: column;
  }
}
