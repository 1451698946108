@import '../../../assets/scss/variables';

.micro_cycle_simulator {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 37px 0 16px 0;

  &__header_container {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header {
    padding-left: 45px;
    font-size: 20px;
  }

  &__header_button_container {
    display: flex;
    align-items: center;
    margin-right: 50px;
    margin-bottom: 32px;
  }

  &__list {
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  &__item {
    display: flex;
    flex-direction: column;
    min-width: 172px;
    flex-grow: 1;
    width: 172px;
    margin: 12px;
    background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 11px;

    color: $white;
    font-size: 14px;
    padding: 26px 21px 21px;

    .risk_display {
      font-size: 14px;
      text-align: center;
      margin-bottom: 8px;
    }

    .risk_display_not_active {
      opacity: 0.5;
    }

    .item {
      display: flex;
      width: 100%;
      font-size: 14px;

      align-items: center;
      justify-content: center;
      //margin-top: 7x;

      span {
        font-size: 14px;
        line-height: 16px;
        font-weight: 200;
        display: flex;
        flex-direction: column;

        min-width: 50%;
        max-width: 60%;
        flex-grow: 1;
      }

      .input {
        background-color: transparent;
        height: 30px;
        outline: none;
        border: 1px solid $white;
        border-radius: 84px;
        //min-width: 30%;
        max-width: 130px;
        color: $white;
        text-align: center;

        &::placeholder {
          color: $white;
          text-align: center;
          font-weight: 200;
          opacity: 0.5;
        }

        &.input_disabled {
          background: #34393f;
          cursor: default;
          color: $white;
          opacity: 0.5;
        }
      }
    }
  }
}

.simulate__btns_block {
  display: flex;
  flex-grow: 1;
  min-width: 50%;
  max-width: 70%;
  width: 100%;
  align-self: center;
  justify-content: space-around;
}

.btn__simulate {
  display: flex;
  width: 205px;
  height: 36px;
  line-height: 34px;
  align-self: center;
  justify-content: center;
  text-align: center;
  color: $white;
  font-size: 14px;
  border: none;
  margin-top: 2px;
  cursor: pointer;
  font-weight: 400;

  background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
  border-radius: 84px;

  &.disabled {
    background: #34393f;
    cursor: default;
    color: $white;
  }
}
.simulation_date_container {
    li {
      max-width: 178px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
}

.disable:active {
  background: #34393f;
  color: $white;
}

/* === Risk Trend Simulator Styles === */

.r_t_simulator {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border: none;

  &__result_item {
    display: flex;
    align-items: center
  }

  &__container {
    width: calc(100% - 282px);
    display: flex;
    flex-direction: column
  }

  &__head {
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
    width: 282px;
    padding-left: 38px;
    display: flex;
    align-items: center;
    margin-bottom: 55px;
  }

  &__indications_block {
    display: flex;
    align-items: center;
    height: 158px;
    background: linear-gradient(90deg, rgba(52, 44, 53, 0) 0%, rgba(47, 53, 57, 0.45) 100%);
    box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.4);

  }
  &__indications_block_radius_left {
    border-top-left-radius: 80px;
    border-bottom-left-radius:80px;
  }

  &__indications_block_radius {
    border-radius: 115px;
  }


  &__indications_container {
    display: flex;

    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100px;

    background: linear-gradient(90deg, rgba(52, 44, 53, 0) 0%, rgba(47, 53, 57, 0.45) 100%);
    box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.4);
    border-radius: 122px;
  }

  &__indications {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    height: 60px;
  }

  &__indicator_top {
    margin-top: 20px;
  }

  &__indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-left: 65px;
    // Text
    &_text {
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
      text-align: center;
      font-weight: 300;

      &.high {
        color: #ad312a;
      }

      &.medium {
        color: #e75d0d;
        margin-top: 10px;
      }

      &.contained {
        color: #ecd858;
      }

      &.low {
        color: #49a0df;
      }
    }
  }

  &__outputs_block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 27px;
  }

  &__outputs_container {
    display: flex;
    width: 83%;
    justify-content: space-around;
    align-items: center;
    align-self: center;
  }

  &__outputs {
    display: flex;
    width: 96%;
    justify-content: space-between;
    align-items: center;
    align-self: center;
  }

  &__output {
    display: flex;
    max-width: 100px;
    width: 100%;
    font-size: 14px;
    align-self: center;
    justify-content: center;
    text-align: center;
    margin-top: 27px;
    margin-left: 40px;
  }
}

.micro_cycle_simulator_preloader {
  margin-top: 97px;
}

.risk__indicator_container {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #2d3036;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 14px;
}

.risk__indicator_container_active {
  box-shadow: 1px 1px 10px white;
}

.risk__indicator {
  display: flex;
  align-self: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  // Text
  &.high {
    background: $risk-adjustments;
  }

  &.contained {
    background: $contained;
  }

  &.medium {
    background: $medium;
  }

  &.low {
    background: $low-lvl;
  }
}

.arrow {
  width: 30px;
  min-width: 30px;
  height: 30px;
  color: #ffffff;
  position: relative;
  left: 110px;
  img {
    color: $white;
  }
}
.arrow_top {
  margin-top: 22px;
}



.line {
  background: linear-gradient(180deg, #34393F 0%, #2F3539 100%);
  opacity: 0.5;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
}

.preloader__text {
  font-size: 14px;
  line-height: 16px;
  opacity: 0.5;
}

.preloader__img {
  width: 31px;
  height: 31px;
}

.margin__preloader {
  margin-bottom: 50px;
}

.local__modal {
  &_header {
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 15px;
  }

  &_line {
    display: flex;
    max-width: 291px;
    width: 100%;
    height: 1px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
    margin: 0 0 12px 0;
  }

  &_validation_block {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 283px;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: 300;
    color: $white;
    margin-bottom: 18px;
  }

  &_error_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
    opacity: 0.5;
    margin-bottom: 9px;

    img {
      display: flex;
      width: 49px;
      height: 49px;
    }

    &_line_top {
      display: flex;
      border-bottom: 1px solid #bb2628;
      width: 25px;
      transform: rotate(45deg);
    }

    &_line_bottom {
      display: flex;
      border-bottom: 1px solid #bb2628;
      width: 25px;
      transform: rotate(-45deg);
    }
  }
}

ul.custom-dot-list-style {
  position: static;
  margin-top: 10px;
}

.simulator {

  &__option {
    padding-bottom: 21px;
  }


  &__container {
  display: flex;
  flex-direction: row;
  }

  &__container_options {
    width: 275px;
    padding-top: 75px;
    padding-left: 35px;
  }

  &__container_carousel {
    display: flex;
    flex-direction: column;
    width: calc(100% - 282px);
    li {
      width: 172px;
      //margin-right: 20px;
    }
  }

  &__option {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    color: #FFFFFF;
    opacity: 0.5;
  }

  &__dot_container {
    display: flex;
    flex-direction: row;
    position: relative;
    top: -7px;
    margin-bottom: 6px;
    margin-left: 27.5%;
    align-items: center;
  }

  &__dot {
    width: 11px;
    height: 11px;
    background: #FFFFFF;
    border-radius: 5.5px;
    margin-right: 9px;
  }

  &__dot_deactive {
    background: gray;
    opacity: 0.7;
  }

  &__dot_button {
    width: 33px;
    height: 33px;
    cursor: pointer;
  }

}
.opacity {
  opacity: 0;
}

.optionItem {
  margin-bottom: 7px;
}

.elected {
  margin-top: 0;
}

@media (max-width: 1300px) {
  .micro_cycle_simulator {
    justify-content: space-between;
    &__item {
      min-width: 200px;
      max-width: 90%;
      width: 100%;

      .item {
        .input {
          //min-width: 60px;
          //max-width: 45%;
        }
      }
    }
  }
}

@media (max-width: 1700px) {
  .micro_cycle_simulator {
    &__item {
      margin-bottom: 24px;

      .risk_display {
        margin-bottom: 20px;
      }
    }
  }

  .micro_cycle_simulator {
    &__item {
      margin-top: 0;
    }
  }

  .simulator {
    &__dot_container {
      margin-left: 23%;
    }
  }
}

@media (max-width: 1900px) {
  .arrow {
    left: 90px;
  }
}

@media (max-width: 1700px) {
  .arrow {
    left: 70px;
  }
}


@media (max-width: 1600px) {
  .arrow {
    left: 60px;
  }
  .simulator {

    &__dot_container {
      margin-left: 18.5%;
    }
  }
}





@media (max-width: 1500px) {
  .simulator {

    &__dot_container {
      margin-left: 14.5%;
    }
  }
  .r_t_simulator {

    &__result_item {
      justify-content: center;
    }

    &__indicator {
      margin-left: 34px;
    }

  }

  .arrow {
    left: 40px;
  }
}

@media (max-width: 1245px) {
  .micro_cycle_simulator {
    &__item {
      min-width: 200px;

      .item {
        span {
          min-width: 40%;
          max-width: 50%;
        }
        .input {
          //min-width: 60px;
          //max-width: 45%;
        }
      }
    }
  }
}

@media (max-width: $screen-tablet) {
  .micro_cycle_simulator {
    &__item {
      min-width: 200px;
      //max-width: 46.5%;

      .item {
        .input {
          //min-width: 40px;
          //max-width: 45%;
        }
      }
    }
  }
}

@media (max-width: 775px) {
  .micro_cycle_simulator {
    &__item {
      width: 100%;
      max-width: 100%;

      .item {
        .input {
          //min-width: 40px;
          //max-width: 45%;
        }
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .micro_cycle_simulator {
    display: none;
  }
}

