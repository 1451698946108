@import '../../assets/scss/variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}

.injury_management {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 29px;
  margin-bottom: 380px;

  &__title {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    font-style: normal;
    color: $white;
    margin-left: 35px;
    margin-bottom: 26px;
  }

  &__select_player_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 491px;
    width: 100%;
  }

  &__report {
    &_block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding-left: 35px;
      padding-right: 50px;
    }

    &_injury {
      width: 25%;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
    }
    &_datepicker {
      width: 50%;

      &__select {
        width: 323px;
        height: 47px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 84px;
        background-color: transparent;
        cursor: pointer;
      }
    }
    &_button {
      width: 25%;

      display: flex;
      max-width: 204px;
      line-height: 34px;
      align-self: center;
      justify-content: center;
      text-align: center;
      color: $white;
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      border: none;
      cursor: pointer;

      background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
      box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
      border-radius: 84px;

      &.disabled {
        background: #34393f;
        cursor: default;
        color: $white;
      }
    }
  }
}

.panel {
  position: relative;
  padding: 26px 51px 19px 23px;
  border: none;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
}

.players_table {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 33px;
  margin-bottom: 47px;

  &__search {
    &_block {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      max-width: 155px;
      width: 100%;
      margin-bottom: 18px;
      margin-left: 14px;
    }

    &_button {
      outline: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      width: 16px;
      height: 16px;
      color: $white;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      &_img {
        width: 16px;
        height: 16px;
        color: $white;
      }
    }
  }

  &__input {
    border: none;
    outline: none;
    background-color: transparent;
    color: #ffffff;
    max-width: 130px;
    width: 100%;
    font-size: 14px;
    margin-bottom: 28px;

    &::placeholder {
      font-size: 14px;
      line-height: 16;
      font-style: normal;
      font-weight: normal;
    }
  }

  &__headers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__item {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-right: 70px;
  }

  &__item_date__block {
    display: flex;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-right: 70px;
    left: -8px;
  }

  &__item_date__block_with_out_team {
    left: -21px;
  }

  &__type {
    position: relative;
    left: 3px;
  }

  &__type_with_out_team {
    left: -20px;
  }

  &__return {
    position: relative;
    left: 17px;
  }

  &__return_with_out_team {
    left: -18px;
  }

  &__player {
    padding-left: 16px;
  }
  &__action {
    text-align: center;
    max-width: 98px;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-right: 28px;
    width: calc(20% - 49px);
    position: relative;
    left: 18px;
  }
  &__action_with_out_team {
    left: 0px;
  }
}

.player {
  display: flex;

  &__details {
    display: flex;
    flex-grow: 1;
    align-items: center;

    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }
  }

  &__details_data_item {
    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 20%;
    height: 41px;
    text-align: start;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }

  &__name {
    position: relative;
    left: 16px;
  }

  &__actions {
    width: 157px;
    padding-right: 49px;
  }

  &__wrap_data_manager {
    display: flex;
    flex-grow: 1;
    width: 33%;
    align-items: center;
  }

  &__button {
    width: 23px;
    height: 23px;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
  }
  &__edit {
    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%) 40% 50% no-repeat;
    background-size: 34px 34px !important;
    position: relative;
    left: 22px;
    width: 34px;
    height: 34px;
  }

  &__delete {
    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%) 45% 50% no-repeat;
    background-size: 40px 40px !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }

  &__title {
    color: $white;
    font-size: 14px;
    font-weight: normal;
    padding-left: 22px;
  }

  &__level {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 16px;
    padding-left: 22px;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  max-width: 40px;
  width: 100%;

  &__buttons {
    display: flex;
    align-self: center;

    &_block {
      display: flex;
      justify-content: flex-end;
      margin-top: 29px;
    }

    &_wrap {
      display: flex;
      max-width: 121px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 300;
  }

  &__button {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.arrow_rotate {
  transform: rotate(180deg);
}

.sorted_button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -20px;
  width: 16px;
  height: 18px;
  cursor: pointer;
  margin-left: 13px;
}

.arrow_rotate__sortDate {
  display: flex;
  align-self: center;

  transform: rotate(90deg);
}

.date_team {
  position: relative;
  right: 13px;
}

.block {
  display: block;
}

.none {
  display: none;
}

.injury_modal {
  padding: 0 3px 8px 7px;
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 35px;
  }

  &__fields_group {
    display: flex;
    flex-direction: column;
    max-width: 490px;
    width: 100%;
    margin-left: 20px;
  }

  &__popup_substrate {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    right: 0;
    transition: left 0.3s ease-in-out;
  }

  &__fields_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
  }

  &__fields_name {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: $white;
    width: 120px;
  }

  &__full_width {
    max-width: 369px;
  }

  &__half_width {
    max-width: 210px;
  }

  &__select {
    position: relative;
    align-items: center;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent calc(100% - 6px) 50% no-repeat;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    width: 100%;
    height: 47px;
    border-radius: 84px;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &_datepicker,
    &_inputField {
      width: 210px;
      padding-left: 31px;
    }

    &_group {
      display: flex;
      height: 47px;
      border: 1px solid $white;
      box-sizing: border-box;
      border-radius: 84px;

      grid-template-areas: 'select';
      grid-area: select;
      position: relative;
      //z-index: 9;
      align-items: center;
      appearance: none;
      background-color: transparent;
      margin: 0;
      outline: none;
      color: #fff;
      cursor: pointer;

      &::-ms-expand {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        display: block;
      }
    }

    &_textarea {
      border: none;
      box-sizing: border-box;
      outline: none;
      color: #ffffff;
      width: 375px;
      padding: 0 30px;
      padding-right: 35px;
      margin-left: -12px;
      z-index: 100;
      position: relative;

      background: #191a1e calc(100% - 13px) 12px no-repeat;
      border-radius: 11px;
      opacity: 1;
      resize: none;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
      border-top: 15px solid transparent;
      border-bottom: 20px solid transparent;

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        opacity: 0.25;

        font-family: 'Roboto', sans-serif;
      }

      &__button_close {
        width: 33px;
        height: 33px;
        background-position: center center;
        background-color: transparent;
        border: none;
        outline: none;
        position: absolute;
        z-index: 101;
        left: 321px;
        //top: 12px;
        top: 5px;
        cursor: pointer;
      }

      &__button {
        display: flex;
        flex-direction: column;
        width: 18px;
        height: 18px;
        position: absolute;
        left: 320px;
        z-index: 101;
        transform: rotate(180deg);

        &_top,
        &_bottom {
          width: 18px;
          height: 9px;
          background-repeat: no-repeat;
          background-position: 100% 0;
        }
        &_bottom {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__select_description {
    line-height: 45px;
    height: 45px;
    overflow: hidden;
    padding-left: 31px;
    padding-right: 31px;
    font-family: 'Roboto', sans-serif;

    font-size: 14px;
    color: rgb(255, 255, 255);
    font-weight: 400;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    max-width: 295px;
    width: 100%;

    &_block {
      display: flex;
      max-width: 490px;
      width: 100%;
      margin-left: 20px;
      justify-content: flex-end;
      margin-top: 11px;
    }
  }

  &__button {
    width: 140px;
    height: 36px;
    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 84px;
    border: none;
    outline: none;
    color: $white;
    cursor: pointer;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    &:active {
      opacity: 0.7;
    }
  }
}

.opacity {
  opacity: 0.25;
  cursor: default;
}

.form-group-title {
  font-size: 22px;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 1em;
}

.form-group + .form-group {
  margin-top: 10px;
}

label {
  cursor: pointer;
}

.dropdown {
  position: relative;
}

.dropdown__button {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%) calc(100% - 8px) 50% no-repeat;
  border-radius: 20px;
  padding: 13px 35px 13px 31px;
  height: 43px;
  top: 2px;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  color: rgb(255, 255, 255);

  background-size: 30px 30px;
  &_long {
    width: 369px;
  }
  &_long__description {
    width: 375px;
  }

  &_opacity {
    color: rgba(255, 255, 255, 0.25);
  }

  &_line {
    display: flex;
    height: 0;
    margin-left: -6px;
    border-top: 0.5px solid rgba(255, 255, 255, 0.25);

    &__short {
      max-width: 140px;
      width: 100%;
    }
    &__long {
      max-width: 297px;
      width: 100%;
    }

    &__datepicker {
      display: flex;
      height: 0;
      position: relative;
      border-top: 0.5px solid rgba(255, 255, 255, 0.25);
      max-width: 140px;
      width: 100%;
      margin-left: 24px;
      top: 23px;
    }
  }
}

.dropdown__button:focus,
.dropdown__button--active {
  outline: none;
  border-bottom: none;
}

.dropdown__button--active {
  //background-color: #191A1E;
  border-radius: 11px 11px 0 0;
  border-color: transparent;

  //box-shadow: 0 -5px 10px 2px rgba(0, 0, 0, 0.2);
  //position: relative;
  //z-index: 11;
}

.dropdown__list {
  display: none;
  position: absolute;
  left: 0;
  top: 0px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-scrolling: touch;
  margin: 0;
  padding-left: 0;
  padding-top: 14px;
  padding-bottom: 17px;
  list-style-type: none;
  background: #191a1e;
  width: 100%;
  z-index: 10;
  border-radius: 11px;
  background-position: calc(100% - 6px) 8px;

  background-repeat: no-repeat;

  &--visible {
    display: block;
    border: none;

    //box-shadow: 0px 0px 2px 5px rgba(0, 0, 0, 0.2);
    //border-radius: 0 0 11px 11px;
  }

  &-item {
    margin: 0 0 0 11px;
    max-width: 154px;
    width: 100%;
    border-bottom: 0;
    padding: 0 20px;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;

    color: rgba(255, 255, 255, 0.75);

    &:hover {
      background: linear-gradient(180deg, #7492c9 0%, #49a0df 100%);
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.25);
      border-radius: 11px;
      //margin-left: 12px;
      //padding-left: 19px;
    }
  }
}

.dropdown__input-hidden {
  display: none;
}

.local__modal {
  &_header {
    text-transform: capitalize;
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 15px;
    font-weight: 500;
    font-style: normal;
    color: $white;
  }

  &_title {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    margin-top: 35px;
    margin-left: 28px;
    color: $white;
  }

  &_validation_block {
    display: flex;
    width: 100%;
    max-width: 283px;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: 300;
    color: $white;
    margin-bottom: 18px;
  }

  &_buttons_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 94px;
  }

  &_buttons_block {
    display: flex;
    justify-content: space-between;
    max-width: 294px;
    width: 100%;
    margin-top: 40px;
  }

  &_button {
    display: flex;
    max-width: 140px;
    width: 100%;
    font-style: normal;
    line-height: 36px;
    align-self: center;
    justify-content: center;
    text-align: center;
    color: $white;
    font-size: 14px;
    border: none;
    margin-top: 16px;
    cursor: pointer;
    font-weight: 400;

    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 84px;
  }
}

.random_player_not_found {
  text-align: center;
  margin-bottom: 21px;
}

.not_found_svg {
  width: 49px;
  height: 49px;
  border-radius: 24.5px;
  border-style: solid;
  border-color: #92a8d1;
  border-width: 0.5px;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;
}

.not_found_svg_x {
  width: 1px;
  height: 23px;
  background-color: #bb2628;
}

.not_found_svg_rotate_right {
  position: relative;
  left: 23px;
  top: 11.5px;
  transform: rotate(-45deg);
}

.not_found_svg_rotate_left {
  position: relative;
  left: 23px;
  bottom: 11.5px;
  transform: rotate(45deg);
}

.line {
  border: 0.1px solid rgba(255, 255, 255, 0.25);
}

.error {
  border-top: 0.5px solid red;
}

.errorModal {
  &_header {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 15px;
  }
  &_please {
    vertical-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin-top: 19px;
    &_bold {
      text-transform: capitalize;
      font-weight: 400;
    }
  }
    &_x {
      margin-top: 18px;
      margin-bottom: 12px;
      cursor: pointer;
    }
}

@media (max-width: 1500px)  {
  .players_table {

    &__item_date__block_with_out_team {
      left: -19px;
    }

    &__type_with_out_team {
        left: -13px;
    }

    &__return_with_out_team {
      left: -4px;
    }

    &__action_with_out_team {
      left: 7px;
    }
    &__type {
      position: relative;
      //left: 6px;
    }
  }
}
