@import '../../assets/scss/variables';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  z-index: 1000;

  @media (max-width: $screen-mobile) {
    position: relative;
    z-index: unset;
  }
}

.container {
  display: flex;
  min-height: 100vh;

  @media (max-width: $screen-tablet) {
    overflow: hidden;
  }

  @media (max-width: $screen-mobile) {
    overflow: visible;
    position: relative;
    left: -306px;
    transition: 0.3s ease-in-out;

    &--open {
      left: 0;
      overflow: hidden;
    }
  }

  @media (max-width: 374px) {
    left: -260px;

    &--open {
      left: 0;
      overflow: hidden;
    }
  }
}

.overlay {
  display: flex;
  min-height: 100vh;
  min-width: 100%;
  z-index: 10000000000;
  opacity: 0.15;
}
