@import '../../assets/scss/variables';

.playersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.actionBar {
  padding: 35px 70px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__filters,
  &__secondaryActions {
    display: flex;
  }

  &__action {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    //text-decoration-line: underline;
    margin-left: 22px;
    opacity: 0.5;
    cursor: pointer;

    img{
      margin-right: 10px;
      width: 15px;
      height: 15px;
    }

    .csvButton {
      display: flex;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        border: none;
        cursor: pointer;
        background-color: transparent;
        color: white;
        padding: 0;
      }
    }
  }
}

// .selectFilter {
//   width: 169px;
//   height: 48px;
//   margin-right: 15px;
// }

.alertGroups {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 0 75px 10px 70px;
  margin-top: 50px;
}

.showAll {
  padding: 0 80px;
  align-self: flex-end;
  font-size: 13px;
  font-weight: lighter;
  opacity: 0.5;
  cursor: pointer;
  text-transform: capitalize;
}

.noPlayersWrap {
  width: 315px;
  height: 260px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 11px;
  align-self: center;
  margin-top: 95px;
  padding: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noPlayers {
  font-size: 20px;
  opacity: 0.5;
}

.accountImg {
  margin-bottom: 23px;
  opacity: 0.5;
}

.resetBtn {
  width: 155px;
  height: 48px;
  background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
  border-radius: 84px;
  border: none;
  margin-top: 25px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 13px;
  }
}

@media (max-width: $screen-tablet) {
  .alertGroups {
    padding: 30px 30px;
  }

  .showAll {
    padding: 0 40px;
  }
}

@media (max-width: $screen-mobile) {
  .playersContainer {
    padding: 0;
  }
  .alertGroups {
    padding: 10px;
  }
  .showAll {
    display: none;
  }

  .actionBar{
    padding: 31px 20px 0;
    flex-direction: column;
   

    &__filters{
      flex-direction: column;
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
