@import '../../assets/scss/variables';

.accordion {
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0;

  img {
    width: 27px;
  }
}

.accordionWrap {
  position: absolute;
  top: 75px;
  right: 67px;
}

.panel {
  position: relative;
  padding: 26px 22px 38px 25px;
  border: none;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
}

.teams {
  display: flex;
  justify-content: space-between;
}

.team {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 70px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: calc(48% - 8px);
    flex-grow: 1;
  }

  &__title {
    color: $white;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin-left: 30px;
  }

  &__numbers {
    align-self: center;
    display: flex;
    margin-top: 36px;

    &Item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 32px;
    }

    & .low {
      color: $low;
    }

    & .high {
      color: #ad312a;
    }
  }

  &__number {
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;
    text-align: center;
  }

  &__numberTitle {
    margin-top: 3px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    line-height: 16px;
  }

  &__search_block {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    max-width: 155px;
    width: 100%;
    margin-bottom: 18px;
    margin-left: 22px;
  }

  &__search_input {
    border: none;
    outline: none;
    background-color: transparent;
    color: $white;
    max-width: 130px;
    width: 100%;
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
      line-height: 16;
      font-style: normal;
      font-weight: normal;
    }
  }
  &__search_button {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: $white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    &_img {
      width: 16px;
      height: 16px;
      color: $white;
    }
  }
}

.players {
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: 1370px;
    padding: 28px 0 0;
  }

  &__title {
    display: flex;
    font-size: 15px;
    padding: 10px 0 7px 0;
    font-weight: 700;
    justify-content: space-between;
    margin-bottom: 9px;

    &Player {
      flex-grow: 1;
      min-width: 33%;
      //padding-left: 22px;
    }

    &Level {
      display: flex;
    }
    &Position {
      flex-grow: 1;
      min-width: 33%;
      //padding-left: 22px;
    }
    &Toggle {
      flex-grow: 1;
      min-width: 3%;
      padding-left: 42px;
      display: flex;
      align-items: center;
    }
  }

  &__title_user {
    display: flex;
    font-size: 15px;
    padding: 10px 0 10px 72px;
    font-weight: 700;
    justify-content: space-between;

    &Player {
      flex-grow: 1;
      min-width: 33%;
      max-width: 75%;
    }

    &Level {
      display: flex;
      flex-grow: 1;
      min-width: 33%;
      max-width: 40%;
      justify-content: center;
    }
  }
}

.player {
  display: flex;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  ul {
    padding-inline-start: 0;
    list-style: none;
    margin: 13px 0;
  }

  &__details {
    display: flex;
    flex-grow: 1;
    align-items: center;

    justify-content: space-between;

    & a {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }
  }

  &__details_data_manager {
    display: flex;
    flex-grow: 1;
    align-items: center;

    justify-content: space-between;

    & a {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }
  }

  &__wrap_data_manager {
    display: flex;
    flex-grow: 1;
    width: 33%;
    align-items: center;
  }

  &__levelList_data_manager {
    flex-grow: 1;
    width: 33%;
  }

  &__level {
    color: #ffffff;
  }

  .dot {
    &.high:before {
      background: #ad312a;
    }

    &.medium:before {
      background: #c66f29;
    }

    &.contained:before {
      background: #e0c222;
    }

    &.low:before {
      background: #49a0df;
    }

    &:before {
      position: absolute;
      width: 6px;
      height: 6px;
      content: '';
      display: flex;
      left: -36px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }
  }

  .dot,
  &__level {
    &.high {
      color: #ad312a;
    }

    &.medium {
      color: #c66f29;
    }

    &.contained {
      color: #e0c222;
    }

    &.low {
      color: #49a0df;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    width: 28px;
    height: 28px;
    margin-right: 25px;
    margin-left: 18px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 23px;
      height: 23px;
      object-fit: cover;
      border-radius: 50%;
    }

    &Initials {
      text-align: center;
      padding-top: 6px;
      background-color: $gray;
      font-size: 9px;
      font-weight: lighter;
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      width: 100%;
      height: 100%;
      color: $white;

      &.high {
        border-color: $high;
      }

      &.medium {
        border-color: $medium;
      }

      &.contained {
        border-color: $yellowhigh;
      }

      &.low {
        border-color: $low;
      }
    }
  }

  &__title {
    color: $white;
    font-size: 14px;
    font-weight: normal;
    //padding-left: 22px;
  }

  &__level {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 16px;
    //padding-left: 22px;
  }
}

.cursor {
  cursor: default;
}

.border_botton_none {
  border-bottom: none !important;
}

.local__modal {
  &_header {
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 15px;
    font-weight: 500;
    font-style: normal;
    color: $white;
  }

  &_title {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 255, 255, 1);
  }

  &_line {
    display: flex;
    max-width: 100%;
    height: 1px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
    margin: 0 0 12px 0;
  }

  &_validation_block {
    display: flex;
    width: 100%;
    max-width: 283px;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: 300;
    color: $white;
    margin-bottom: 18px;
  }

  &_error_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
    opacity: 0.5;
    margin-bottom: 9px;

    img {
      display: flex;
      width: 49px;
      height: 49px;
    }

    &_line_top {
      display: flex;
      border-bottom: 1px solid #bb2628;
      width: 25px;
      transform: rotate(45deg);
    }

    &_line_bottom {
      display: flex;
      border-bottom: 1px solid #bb2628;
      width: 25px;
      transform: rotate(-45deg);
    }
  }

  &_buttons_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 94px;
  }

  &_buttons_block {
    display: flex;
    justify-content: space-between;
    max-width: 80%;
    width: 100%;
    margin-top: 40px;
  }

  &_icons_block {
    display: flex;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }

  &_button {
    font-family: 'Roboto';
    font-style: normal;
    max-width: 140px;
    width: 100%;
    line-height: 17px;
    text-align: center;
    color: $white;
    font-size: 14px;
    border: none;
    margin-top: 16px;
    cursor: pointer;
    font-weight: 400;
    height: 36px;

    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 84px;
  }
}

.btn_simulator {
  display: flex;
  width: 49px;
  height: 24px;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  margin-left: 54px;
}

.btn__switch_case {
  display: flex;
  width: 49px;
  height: 24px;
  align-items: center;
  background: linear-gradient(90deg, rgba(52, 44, 53, 0) 0%, rgba(47, 53, 57, 0.45) 100%);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.48);
  border-radius: 22px;
  cursor: pointer;
}

.btn__start {
  justify-content: flex-start;
}

.btn__end {
  justify-content: flex-end;
}

.btn__switch_case_for {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  text-align: center;
  background: #2d3036;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
  margin: 0 4px 0 4px;
}

.btn__switch_case_for_off {
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(180deg, #bb2628 0%, #b03b2a 100%);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
  transform: rotate(-180deg);
}

.btn__switch_case_for_on {
  display: flex;
  width: 12px;
  height: 12px;
  background: #27ae60;
  border-radius: 50%;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
  transform: rotate(-180deg);
}

.simulator__mode_text {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  font-size: 14px;
  width: 121px;
  font-weight: 300;
  color: $white;

  span {
    font-weight: 400;
    margin-left: 3px;
  }
}

.opacity {
  opacity: 0.7;
}

.pagination {
  &__buttons_block {
    display: flex;
    justify-content: flex-end;
    margin-top: 19px;
  }
  &__buttons_wrap {
    display: flex;
    max-width: 121px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-right: 27px;
  }

  &__buttons {
    display: flex;
    align-self: center;
  }

  &__text {
    font-size: 14px;
    font-weight: 300;
  }

  &__button {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  display: flex;
  justify-content: space-between;
  max-width: 40px;
  width: 100%;
}

.arrow_rotate {
  transform: rotate(180deg);
}

.player {
  &__wrap {
    display: flex;
    min-width: 77.5%;
    align-items: center;
  }

  &__levelList {
    min-width: 20%;
  }

  .dot,
  &__level {
    position: relative;

    &.high {
      color: #ad312a;
    }

    &.medium {
      color: #c66f29;
    }

    &.contained {
      color: #e0c222;
    }

    &.low {
      color: #49a0df;
    }
  }
}

.injury_modal {
  padding: 0 3px 8px 7px;

  &__popup_substrate {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    right: 0;
    transition: left 0.3s ease-in-out;
    z-index: 10;
  }
}

.block {
  display: block;
}

.none {
  display: none;
}

@media (max-width: 1199px) {
  .team {
    &__wrapper {
      max-width: 100%;
    }
  }
}

@media (max-width: $screen-mobile) {
  .players {
    &__title {
      padding: 0;
      margin-left: 0;

      &Player {
        padding-left: 13px;
      }

      &Level {
        padding-left: 10px;
        justify-content: flex-start;
      }
    }
  }

  .accordionWrap {
    top: 17px;
    right: 20px;
  }

  .player {
    flex-direction: column;
    font-size: 17px;

    &__wrap {
      min-width: 60%;
    }

    &__image {
      display: none;
    }

    &__levelList {
      min-width: 40%;
      padding-left: 10px;
    }

    &__title {
      margin-left: 13px;
      font-size: 12px;
      line-height: 14px;
    }

    .dot {
      &:before {
        left: -16px;
      }
    }
  }

  .panel {
    padding: 17px 20px 29px 22px;
    min-width: 100%;
  }

  .player__level {
    padding: 0;
    font-size: 12px;
    line-height: 14px;
  }

  .teams {
    flex-direction: column;
  }

  .team {
    margin-bottom: 10px;

    &__numberTitle {
      font-size: 12px;
      line-height: 14px;
    }

    &__numbers {
      margin-top: 22px;

      &Item {
        padding: 0 25px;
        color: #95979a;
      }
    }

    &__title {
      padding: 0;
      font-size: 15px;
      line-height: 18px;
      font-weight: normal;
    }

    &__wrapper {
      max-width: 100%;
    }
  }
}

.sortArrow {
  margin-right: 7px;
  width: 1px;
  height: 5px;
  align-self: center;
  margin-bottom: 3px;
}
