@import '../../assets/scss/variables';

.contactUsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 100px;
}

.panel {
  margin-top: 20px;
  padding: 32px 36px 36px;
  border: none;
  position: relative;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
}

.tooltip {
  height: 15px;
  width: 15px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 15px;
  position: absolute;
  right: 18px;
  top: 18px;
}

.contact {
  display: flex;
  width: 100%;

  &__wrapper {
    display: flex;
    align-items: stretch;
    width: 100%;
    padding: 0 45px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;

    & form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      width: 100%;
    }
  }

  &__block1,
  &__block2 {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 73px;
    margin-bottom: 42px;
  }

  &__block3 {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  &__firstName,
  &__lastName,
  &__team,
  &__title {
    min-width: 50%;
  }

  &__submit {
    margin-top: 40px;
    width: 204px;
    height: 36px;
    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 84px;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    border: none;
    cursor: pointer;

    &:active {
      background: #34393f;
    }
  }

  &__label {
    padding: 0 10px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
  }

  & input {
    font-family: 'Roboto', sans-serif;
    min-width: 100%;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 84px;
    height: 47px;
    background: transparent;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    color: #ffffff;

    &::placeholder {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      line-height: 16px;
      font-style: normal;
      font-weight: normal;
      color: #ffffff;
    }
  }

  &__message {
    width: 100%;

    &Item {
      width: 100%;
      position: relative;

      &_count {
        position: absolute;
        bottom: 20px;
        right: 34px;
        padding: 0;
        font-size: 10px;
        line-height: 12px;
        margin: 0;
      }

      & textarea {
        width: 100%;
        height: 238px;
        font-family: 'Roboto', sans-serif;
        resize: none;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        padding: 16px 22px;
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 25px;

        &::placeholder {
          color: $white;
        }
      }
    }
  }

  &__success {
    &Icon {
      align-self: center;
      width: 38px;
      height: 38px;
      margin: 20px;
    }
  }
}

.success {
  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200000000;
  }

  &__wrapper {
    padding: 24px 15px;
    background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    width: 321px;
    max-width: 90%;
    position: relative;
    z-index: 2;
  }

  &__form-back {
    background: rgba(25, 26, 30, 0.85);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    padding-bottom: 10px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
  }

  &__text {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin-top: 12px;
  }

  &__image-wrapper {
    margin-top: 18px;
    display: flex;
    justify-content: center;

    img {
      width: 49px;
      height: 49px;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 15px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;

    img {
      width: 25px;
      height: 25px;
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: $screen-tablet) {
  .contact {
    &__block1,
    &__block2 {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: $screen-mobile) {
  .panel {
    padding: 0 25px;
    background: none;
    box-shadow: none;
  }

  .contact {
    &__wrapper {
      padding: 0;
    }

    &__block1,
    &__block2 {
      grid-template-columns: 1fr;
      grid-gap: 22px;
      margin-bottom: 22px;
    }

    &__message {
      &Item {
        & textarea {
          border-radius: 23px;
          height: 130px;
        }

        &_count {
          right: 17px;
        }
      }
    }

    & .tooltip {
      display: none;
    }
  }
}
