@import '../../assets/scss/variables';

.modalOverlay {
  background-color: rgba(25, 26, 30, 0.85);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 130px;
  right: 0;
  z-index: 1000;
  transition: left 0.3s ease-in-out;

  &Collapsed {
    left: 276px;
  }
}

.modalBody {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;

  flex-direction: column;
  min-height: 270px;
  width: 80%;
  max-width: 1000px;
  padding: 23px 20px 15px 16px;
  border: none;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  z-index: 1000001;
}

.modalClose {
  //display: flex;
  display: none;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: -20px;
  z-index: 100;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;

  img {
    transform: rotate(45deg);
  }
}

.modalClose:hover {
  opacity: 1;
}

@media (max-width: $screen-mobile) {
  .modalBody {
    min-height: 114px;
  }

  .modalOverlay {
    z-index: 90000;
    left: 0;

    &Collapsed {
      left: 0;
    }
  }
}

@media (max-width: 665px) {
  .modalBody {
    width: 356px;
  }
}

.modalBodyBlack {
  background: #191a1e;
}
.modalOverlayUpper {
  z-index: 10;
  background-color: transparent;
}
