@import '../../../assets/scss/variables';

.panel {
  margin-top: 10px;
  padding: 26px 38px 0;
  border: none;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
  position: relative;
}

.player__tooltip {
  display: inline-block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 16px;
  position: absolute;
  top: 18px;
  right: 18px;
}

.riskFactor {
  display: flex;
  flex-direction: column;
  min-width: 48%;
  flex-grow: 1;

  & .workload {
    &__mobile {
      display: none;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
  }

  &__subtitle {
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
  }

  & ul,
  &__list {
    margin: 20px 0 0 0;
    padding-left: 56px;
    list-style: none;

    &_itemCenter {
      align-items: center;
    }
  }

  &Item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px 10px 10px 0;
    border-radius: 20px;
    align-items: center;
    cursor: pointer;

    &__name,
    &__level {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $white;
    }

    &.hi {
      &.high,
      &.alto {
        .riskFactorItem__name:before {
          background: linear-gradient(180deg, #bb2628 0%, #b03b2a 100%);
        }
      }

      &.medium,
      &.intermedio {
        .riskFactorItem__name:before {
          background-color: $redmed;
        }
      }

      &.low,
      &.bajo {
        .riskFactorItem__name:before {
          background-color: $redcont;
        }
      }
    }

    &__name {
      position: relative;
      font-size: 14px;

      &:before {
        content: '';
        display: flex;
        //background: linear-gradient(180deg, #BB2628 0%, #B03B2A 100%);
        position: absolute;
        top: 3px;
        left: -31px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
      }

      // &Active {
      //   cursor: pointer;
      // }
    }

    &__levelWrapper {
      background: linear-gradient(90deg, rgba(52, 44, 53, 0) 0%, rgba(47, 53, 57, 0.45) 100%);
      box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.48);
      border-radius: 22px;
      padding: 10px;
      margin-left: 10px;
    }

    &__level {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 95px;
      height: 26px;
      border-radius: 13px;
      text-align: center;
      text-transform: capitalize;
      font-weight: 300;
      font-size: 14px;

      &.hi {
        &.high,
        &.alto {
          background-color: $redhigh;
        }

        &.medium,
        &.intermedio {
          background-color: $redmed;
        }

        &.low,
        &.bajo {
          background-color: $redcont;
        }
      }

      &.me {
        &.high,
        &.alto {
          background-color: $orangehigh;
        }

        &.medium,
        &.intermedio {
          background-color: $orangemed;
        }

        &.low,
        &.bajo {
          background: linear-gradient(180deg, #e0c222 0%, #f7f410 100%);
        }
      }

      &.co {
        color: $black32;

        &.high,
        &.alto {
          background-color: $yellowhigh;
        }

        &.medium,
        &.intermedio {
          background-color: $yellowmed;
        }

        &.low,
        &.bajo {
          background-color: $yellowcont;
        }
      }
    }
  }

  & .dot {
    &::marker {
      font-size: 24px;
      margin-right: 0;
      line-height: 30px;
      margin-left: 10px;
    }

    &.hi {
      &.high,
      &.alto {
        color: $redhigh;
        .riskFactorItem__name:before {
          background: $redhigh;
        }
      }

      &.medium,
      &.intermedio {
        color: $redmed;
        .riskFactorItem__name:before {
          background: $redmed;
        }
      }

      &.low,
      &.bajo {
        color: $redcont;
        .riskFactorItem__name:before {
          background: $redcont;
        }
      }
    }

    &.me {
      &.high,
      &.alto {
        color: $orangehigh;
        .riskFactorItem__name:before {
          background: $orangehigh;
        }
      }

      &.medium,
      &.intermedio {
        color: $orangemed;
        .riskFactorItem__name:before {
          background: $orangemed;
        }
      }

      &.low,
      &.bajo {
        color: $orangecont;
        .riskFactorItem__name:before {
          background: $orangecont;
        }
      }
    }

    &.co {
      &.high,
      &.alto {
        color: $yellowhigh;
        .riskFactorItem__name:before {
          background: $yellowhigh;
        }
      }

      &.medium,
      &.intermedio {
        color: $yellowmed;
        .riskFactorItem__name:before {
          background: $yellowmed;
        }
      }

      &.low,
      &.bajo {
        color: $yellowcont;
        .riskFactorItem__name:before {
          background: $yellowcont;
        }
      }
    }
  }

  & .workload {
    padding: 19px 20px;

    &__wrap {
      display: flex;
      width: 100%;
      position: relative;

      //top: -25px;
      top: -20px;
    }

    &__title {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }

    &Legend {
      display: flex;
      min-width: 520px;
      text-transform: capitalize;
      font-size: 13px;
      font-weight: lighter;
      justify-content: flex-end;
      bottom: -3px;
      right: 0;
      position: absolute;
      list-style-type: disc;
      margin: 0 !important;

      &__name {
        color: $white;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;

        position: absolute;
        bottom: 6px;
        left: 2px;
      }

      &__item {
        flex-grow: 1;
        position: relative;
        top: 3px;

        &.dot {
          &.standard {
            color: $gray6e;
          }

          &.high {
            color: $high;
          }

          &.low {
            color: $low;
          }
        }
      }
    }
  }
}

@media (max-width: 1025px) and (min-width: 1024px) {
  .riskFactor {
    & .dot::marker {
      font-size: 24px;
      line-height: 30px;
    }
    & ul,
    &__list {
      padding: 0;
    }
    .workloadLegend {
      min-width: 340px !important;
      //top: 0;
      bottom: 10px;
      &__name {
        position: relative;
        left: -5px;
      }
    }
  }
  .workload {
    &__wrap {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 1000px) {
  .riskFactor {
    & ul,
    &__list {
      padding-left: 25px;
    }
    .workloadLegend {
      max-width: 50%;
      top: 15px;
      right: 10px;
      left: -5px;
      &__name {
        position: relative;
        left: -5px;
      }
    }
  }
  .workload {
    &__wrap {
      margin-bottom: 20px;
    }
  }
}

@media (min-width: $screen-mobile) and (max-width: 1024px) {
  .panel {
    padding: 17px 22px 0 22px;
  }

  .riskFactor {
    &__title {
      font-size: 20px;
      line-height: 18px;
    }

    & .dot::marker {
      //line-height: 20px;
      position: relative;
      //font-size: 24px;
      line-height: 30px;
    }

    &Item {
      padding-right: 0;
      padding-top: 0;

      &__name {
        font-size: 12px;

        &:before {
          display: none;
        }
      }

      &__level {
        max-width: 116px;
        align-self: center;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .player__tooltip {
    margin-left: 0;
    //margin-right: 10px;
    top: 16px;
    right: 16px;
  }

  .workload {
    &__wrap {
      top: -20px !important;
    }
    &__title {
      margin-bottom: 10px;
    }
    &Legend {
      max-width: 50%;
      padding-top: 20px;
      padding-left: 10px;
      display: flex;

      &__name {
        padding-bottom: 3px;

        top: -2px !important;
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .riskFactor {
    & ul,
    &__list {
      padding: 0;
      list-style-type: none;
    }
    &Item {
      &__name {
        font-size: 12px;
        &:before {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .workload {
    &Legend__safari {
      margin-top: 20px;
      top: 30px !important;
      left: 30px !important;
    }
  }
}

@media (min-width: 766px) and (max-width: $screen-mobile) {
  .workload {
    &__wrap {
      margin-left: 10px;
      left: 10px;
    }
    &Legend {
      margin-left: 10px;
      &__name {
        padding-bottom: 3px;

        top: -2px !important;
      }
    }
  }
}

@media (max-width: 750px) and (max-height: 390px) {
  .workload {
    &Legend {
      margin-top: 20px;
      top: 10px !important;
      left: 30px !important;
    }
    &Legend__safari {
      margin-top: 20px;
      top: 30px !important;
      left: 30px !important;
    }
  }
}

@media (max-width: 450px) {
  .riskFactor {
    .workload {
      display: none;
      &__mobile {
        display: flex;
        flex-direction: column;

        &Title {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 12px;

          &:after {
            background: rgba(255, 255, 255, 0.25);
            height: 1px;
            width: 100%;
            display: flex;
            content: '';
            margin-top: 15px;
          }
        }

        &Content {
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}
