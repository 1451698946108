@import '../../assets/scss/variables';

.teamContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 10px 75px 10px 66px;
}

@media (max-width: $screen-tablet) {
}

@media (max-width: $screen-mobile) {
  .team {
    padding: 10px;
  }
}
