@import '../../../assets/scss/variables';

.panel {
  padding: 14px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  &.micro_cycle {
    padding: 0;
    //margin-top: 40px;
  }
}

.player__tooltip {
  display: inline-block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray7e;
  border-radius: 50%;
  line-height: 16px;
}

.contextReference {
  .context {
    display: flex;
    padding: 10px 10px 16px 0;

    &__title {
      display: flex;
      margin-right: 80px;
    }

    &__next {
      display: flex;
      margin-left: 46px;
      position: relative;
      flex-grow: 1;

      &:after {
        content: '';
        position: absolute;
        left: 11px;
        bottom: 0;
        width: calc(90% - 16px);
        border-bottom: 1px solid $black40;
      }

      &Title {
        margin-bottom: 16px;
        font-weight: 300;
        flex-grow: 1;
        max-width: 33%;
      }

      &Short {
        flex-grow: 1;
        max-width: 66%;
        font-weight: lighter;
        opacity: 0.75;
        padding-left: 16px;
      }
    }
  }

  .reference {
    display: flex;
    flex-direction: column;

    &__title {
      padding: 0 10px 10px 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-transform: capitalize;
    }

    &__click {
      font-size: 12px;
    }

    &__dates {
      display: flex;
      margin-left: 12px;
      margin-top: 6px;
      &.micro_cycle {
        min-width: 334px;
        flex-wrap: wrap;
      }
    }

    &__date {
      background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
      box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
      border-radius: 11px;
      padding: 17px 16px 14px;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      margin-right: 16px;
      margin-top: 0;
      min-width: 150px;
      margin-bottom: 16px;
      &Active {
        cursor: pointer;
      }
    }
  }
}

.recommended {
  display: flex;
  flex-direction: column;

  &__total {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 18px;
    padding-left: 8px;
  }

  &__date {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 12px;
  }

  &__drills {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 6px;
    padding: 0 30px;
  }

  &__drill {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 17px 0;

    &:first-child {
      border-bottom: none;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      padding-bottom: 8px;
    }
  }

  &__name {
    width: 70%;
  }

  &__duration {
    width: 30%;
  }
}

@media (max-width: $screen-laptop) {
  .contextReference {
    .reference {
      &__dates {
        &.micro_cycle {
          min-width: 190px;
        }
      }
    }
  }
}

@media (max-width: $screen-tablet) {
}

@media (max-width: $screen-mobile) {
  .panel {
    background: linear-gradient(180deg, rgba(44, 47, 53, 0.5) 0%, rgba(47, 53, 57, 0.5) 100%);
    border-radius: 11px;
    &:not(.micro_cycle) {
      box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    }
  }

  .contextReference {
    .context {
      flex-direction: column;
      position: relative;

      &__title {
        font-size: 17px;
        position: relative;
        width: 100%;
        margin-bottom: 20px;
      }

      &__next {
        padding-bottom: 14px;
        margin-left: 10px;
      }

      &__nextTitle {
        font-size: 14px;
        min-width: 110px;
        margin-right: 0;
      }

      &__nextShort {
        font-size: 14px;
        margin-left: 10px;
      }

      & .player__tooltip {
        position: absolute;
        top: 10px;
        right: -10px;
      }
    }

    .reference {
      flex-direction: column;
      font-size: 14px;

      &__title {
        font-size: 15px;
        line-height: 18px;
      }

      &__dates {
        flex-direction: column;
        margin-left: 0;
      }

      &__date {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: lighter;
        min-height: 50px;
        padding: 20px;
        margin-right: 0;
      }
    }
  }

  .recommended {
    padding: 0 7px;

    &__drills {
      margin-top: 10px;
      padding: 0;
    }
  }

  .player__tooltip {
    margin-left: 0;
    margin-right: 10px;
  }
}
