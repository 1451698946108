@import '../../../assets/scss/variables';

.edit_injury_block {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
  padding-bottom: 20px;
}

.edit_injury__line {
  display: flex;
  height: 1px;
  align-self: center;
  max-width: 1054px;
  width: 100%;
  border-top: 0.5px solid rgba(255, 255, 255, 0.25);
}

.block {
  display: block;
}

.none {
  display: none;
}

.injury_modal {
  padding: 0 3px 8px 7px;
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 35px;
  }

  &__popup_substrate {
    //background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    right: 0;
    transition: left 0.3s ease-in-out;

    //&Collapsed {
    //  left: 276px;
    //}
  }

  &__fields_group {
    display: flex;
    flex-direction: column;
    max-width: 490px;
    width: 100%;
    margin-left: 29px;
  }

  &__fields_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
  }

  &__fields_name {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: $white;
    width: 120px;
  }

  &__full_width {
    max-width: 369px;
  }

  &__half_width {
    max-width: 210px;
  }

  &__select_description {
    line-height: 45px;
    height: 45px;
    overflow: hidden;
    padding-left: 35px;
    padding-right: 40px;
    font-family: 'Roboto', sans-serif;

    font-size: 14px;
    color: rgb(255, 255, 255);
    font-weight: 400;
  }

  &__select {
    position: relative;
    align-items: center;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent calc(100% - 6px) 50% no-repeat;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    width: 100%;
    height: 47px;
    border-radius: 84px;
    background-size: 30px 30px;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &_datepicker,
    &_inputField {
      width: 210px;
      padding-left: 31px;
    }

    &_group {
      display: flex;
      height: 47px;
      border: 1px solid $white;
      box-sizing: border-box;
      border-radius: 84px;
      //grid-template-areas: "select";
      grid-area: select;
      position: relative;
      align-items: center;
      appearance: none;
      background-color: transparent;
      margin: 0;
      outline: none;
      color: #fff;
      cursor: pointer;

      &::-ms-expand {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        display: block;
      }
    }

    &_textarea {
      border: none;
      box-sizing: border-box;
      outline: none;
      color: #ffffff;
      width: 374px;
      padding: 0px 30px;
      padding-right:  40px;
      margin-left: -12px;
      z-index: 100;
      position: relative;
      background: #191a1e calc(100% - 13px) 12px no-repeat;
      border-radius: 11px;
      opacity: 1;
      resize: none;
      font-family: 'Roboto', sans-serif;
      border-top: 15px solid transparent;
      border-bottom: 20px solid transparent;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        opacity: 0.25;

        font-family: 'Roboto', sans-serif;
      }

      &__button_close {
        width: 33px;
        height: 33px;
        background-position: center center;
        background-color: transparent;
        border: none;
        outline: none;
        position: absolute;
        z-index: 101;
        left: 320px;
        top: 12px;
        cursor: pointer;
      }

      &__button {
        display: flex;
        flex-direction: column;
        width: 18px;
        height: 18px;
        position: absolute;
        left: 328px;
        z-index: 101;
        transform: rotate(180deg);

        &_top,
        &_bottom {
          width: 18px;
          height: 9px;
          background-repeat: no-repeat;
          background-position: 100% 0;
        }
        &_bottom {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    max-width: 295px;
    width: 100%;

    &_block {
      display: flex;
      max-width: 490px;
      width: 100%;
      margin-left: 20px;
      justify-content: flex-end;
    }
  }

  &__button {
    display: flex;
    height: 36px;
    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 84px;
    border: none;
    outline: none;
    cursor: pointer;

    line-height: 34px;
    align-self: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    margin-top: 33px;
    font-weight: 400;
    padding: 0;

    &:active {
      opacity: 0.7;
    }

    &_inModal {
      width: 140px;
    }
    &_openModal {
      width: 204px;
    }
  }
}

.dropdown__button {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%) calc(100% - 12px) 50% no-repeat;
  border-radius: 20px;
  padding: 13px 35px 13px 31px;
  height: 45px;
  top: 1px;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;

  background-size: 30px 30px !important;

  color: #FFFFFF;

  &_long {
    width: 369px;
    border-radius: 25px;
  }
  &_long__description {
    width: 375px;
  }

  &_short {
    width: 210px;
  }

  &_line {
    display: flex;
    height: 0;
    margin-left: -6px;
    border-top: 0.5px solid rgba(255, 255, 255, 0.25);

    &__short {
      max-width: 140px;
      width: 100%;
    }
    &__long {
      max-width: 297px;
      width: 100%;
    }

    &__datepicker {
      display: flex;
      height: 0;
      position: relative;
      border-top: 0.5px solid rgba(255, 255, 255, 0.25);
      max-width: 140px;
      width: 100%;
      margin-left: 24px;
      top: 23px;
    }
  }
}

.close_button {
  position: relative;
  display: block;
  width: 30px !important;
  height: 30px;
  background-color: transparent;
  border-radius: 20px;
  top: -6px;
  left: 140px;
  cursor: pointer;
  outline: none;
  border: none;
  //z-index: 9;

  background-size: 30px 30px !important;
}

.dropdown__button:focus,
.dropdown__button--active {
  outline: none;
  border-bottom: none;
}

.dropdown__button--active {
  //background-color: #191A1E;
  border-radius: 11px 11px 0 0;
  border-color: transparent;
}

.dropdown__list {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  max-height: 400px;
  overflow-y: scroll;
  overflow-scrolling: touch;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  background-color: #191a1e;
  padding-top: 14px;
  padding-bottom: 17px;
  width: 100%;
  z-index: 1;
  border-radius: 11px;
  background-position: calc(100% - 6px) 8px;

  background-repeat: no-repeat;
}

.dropdown__list--visible {
  display: block;
  border: none;
}

.dropdown__list-item {
  margin: 0 0 0 11px;
  max-width: 154px;
  width: 100%;
  border-bottom: 0;
  padding: 0 20px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 29px;

  color: rgba(255, 255, 255, 0.75);
}

.dropdown__list-item:hover {
  background: linear-gradient(180deg, #7492c9 0%, #49a0df 100%);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
}

.dropdown__input-hidden {
  display: none;
}

.error {
  border-top: 0.5px solid red;
}
