@import '../../assets/scss/variables';

.historyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.history {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 10px 45px;
}

@media (max-width: $screen-tablet) {
}

@media (max-width: $screen-mobile) {
  .history {
    padding: 10px;
  }
}
