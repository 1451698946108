$black0: #000000;
$black20: #171717;
$black32: #202020;
$black40: #282828;

$white: #ffffff;
$gray: #404040;
$gray78: #787878;
$grayinjured: #9ea2a8;
$gray6e: #6e6e6e;
$gray7e: #c4c4c425;
$gray8e: #34393f;
$lightgray: #d2d2d2;

$high: #B03B2A;
$medium: #CB8F29;
$low: #49A0DF; 

$risk-adjustments: linear-gradient(180deg, #BB2628 0%, #B03B2A 100%);
$contained: linear-gradient(180deg, #ecd858 0%, #e0d35a 100%);
$back-to-safe: linear-gradient(180deg, #7492c9 0%, #49a0df 100%);
$medium-lvl: linear-gradient(180deg, #C66F29 0%, #CB8F29 100%);
$low-lvl: linear-gradient(180deg, #7492C9 0%, #49A0DF 100%);
$gray-lvl: linear-gradient(180deg, #626971 0%, #95979a 100%);

$redhigh: #c61d17;
$redmed: #d13718;
$redcont: #d54d17;
//$redhigh: #bf2115;
//$redmed: #f50f00;
//$redcont: #e03300;
$orangehigh: #fa4500;
$orangemed: #eb7800;
$orangecont: #ed9e00;
$yellowhigh: #f5ba00;
$yellowmed: #fad636;
$yellowcont: #fced45;

$alertmessagestart: #f50d00;
$alertmessageend: #d9341e;

$screen-mobile: 767px;
$screen-tablet: 1023px;
$screen-laptop: 1440px;
//$screen-mobile: 375px;
//$screen-tablet: 768px;

//$redmed_start: #f50d00;
//$redmed_end: #de1a00;
//
//$orangehigh_start: #ff2e00;
//$orangehigh_end: #fa4200;
//
//$yellowhigh_start: #f5ba00;
//$yellowhigh_end: #ffff00;
//
//$low_start: #708fff;
//$low_end: #00ffff;
