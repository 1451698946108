@import '../../../assets/scss/variables';

.panel {
  padding: 24px 30px;
  margin-top: 10px;
  border: none;
  border-radius: 11px;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
}

.riskHeatmap {
  &__title {
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & ul li {
      color: $lightgray;
    }
  }

  & a {
    color: $white;
  }

  &__sort {
    display: flex;
    justify-content: space-between;
    flex-grow: 0.3;
    margin-top: 6px;

    &Item {
      position: relative;
      padding: 0 0 4px 0;
      font-size: 14px;
      line-height: 16px;
      opacity: 0.5;
      cursor: pointer;

      &.active {
        opacity: 1;
        &:after {
          border-bottom: 1px solid $white;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 30%;
        bottom: 0;
        width: 25px;
        border-bottom: 1px solid $gray;
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
  }

  &__injury,
  &__highRisk,
  &__mediumRisk,
  &__lowRisk {
    color: $white;
    font-size: 12px;
    line-height: 14px;
    padding-right: 40px;
    position: relative;

    &:before {
      content: '';
      display: flex;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
    }

    &Ul {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
  &__injury{
    &:before {
      background: linear-gradient(180deg, #626971 0%, #95979a 100%), #626971;
    }
  }
  &__highRisk{
    &:before {
      background: $high;
    }
  }
  &__mediumRisk{
    &:before {
      background: $medium;
    }
  }
  &__lowRisk{
    &:before {
      background: $low;
    }
  }

  &__message {
    display: none;
  }
}

@media (max-width: $screen-tablet) {
  .riskHeatmap__injury {
    padding-right: 30px;
  }
}

@media (max-width: $screen-mobile) {
  .panel {
    padding: 17px 22px;
  }
}

@media (max-width: 450px) {
  .riskHeatmap {
  
    ul{
      display: none;;
    }
    
    &__message {
      display: block;
    }

    &__title {
      font-size: 15px;
      line-height: 18px;
    }

    &__head {
      position: relative;
    }

    &__message {
      margin-top: 10px;
      font-size: 15px;
      line-height: 18px;
    }

    &__sort {
      display: none;
      position: absolute;
      top: 40px;
      left: 15%;
      z-index: 1;
    }

    &__injuryUl {
      display: none;
    }

    &__chart {
      display: none;
    }
  }
}
