@import 'variables';
@import 'fonts';

html {
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: #34393f;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  line-height: 1.6;
  font-size: 16px;
  background: linear-gradient(180deg, #34393f 0%, #18191d 100%);
  color: $white;
}

* {
  box-sizing: border-box;
  outline: none;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

button {
  font-family: 'Roboto', sans-serif;
}
input {
  font-family: 'Roboto', sans-serif;
}
main {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(100% - 320px);
  justify-content: flex-start;
  align-items: flex-start;
  transition: 0.3s ease-in-out;

  &.collapsed {
    max-width: calc(100% - 320px);
    width: calc(100% - 320px);
    overflow: hidden;
  }

  @media (max-width: $screen-mobile) {
    width: 100vw !important;
    max-width: 100vw !important;
    flex: 100vw 0;
    min-width: 100vw !important;
  }
}

.bar_tooltip {
  font-size: 26px;
  text-align: center;
  margin-top: 10px;
  &.High,
  &.Alto {
    color: $high;
  }
  &.Medium,
  &.Medio {
    color: $medium;
  }
}

.datePicker {
  width: 308px;
  max-width: 100%;
  height: 47px;
  border: 1px solid #ffffff;
  border-radius: 84px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: transparent;
}

.react-datepicker {
  border: 2px solid $gray !important;
  background-color: $black20 !important;
  color: $white !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  font-weight: lighter !important;

  &__header {
    background-color: $black20 !important;
  }

  &__current-month,
  &__day,
  &__day-name {
    color: $white !important;
    opacity: 0.6;
  }
  &__current-month,
  &__day,
  &__day-name {
    &--disabled {
      color: $gray !important;
    }
  }
  &__day {
    &--selected {
      background-color: transparentize($white, 0.7) !important;
    }
    &:hover {
      background-color: transparentize($white, 0.8) !important;
    }
  }
}

//.apexcharts-tooltip {
//    background: #f3f3f3;
//    color: orange;
//}

.apexcharts-xaxis-label {
  fill: #ffffff50;
}

//.apexcharts-legend-text {
//    padding-left: 12px;
//}

.apexcharts-marker[selected='true'] {
  r: 6;
}
.apexcharts-marker[selected='false'] {
  r: 4;
}
.preloaderCont {
  display: flex;
  justifycontent: center;
  alignitems: center;
  width: 100%;
  height: 100vh;
}

.mainContent{
  // max-width: 1200px;
  width: 100%;
}

@media (max-width: $screen-mobile) {
  .datePicker {
    font-size: 14px;
    margin-left: 0;
    width: 190px;
    padding: 3px 16px 5px 16px;
  }
}
