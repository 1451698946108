@import '../../assets/scss/variables';

.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  flex-direction: column;
}

@media (max-width: $screen-tablet) {
}

@media (max-width: $screen-mobile) {
}