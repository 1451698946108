@import '../../../assets/scss/variables';

.playerCard {
  width: 100%;
  height: 115px;
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
  margin-bottom: 20px;
  padding: 20px 25px;

  .link {
    display: flex;
    cursor: pointer;
    color: inherit;
    align-items: center;
    justify-content: space-between;

    .player {
      display: flex;
      align-items: center;

      &__image {
        margin-right: 30px;
        border-radius: 50%;
        padding: 5px;
        position: relative;
        &.high {
          background: $risk-adjustments;
        }
        &.medium {
          background: $medium-lvl;
        }
        &.low {
          background: $low-lvl;
        }
        &.injured {
          background: $gray-lvl;
        }
        &.contained {
          background: $contained;
        }

        img {
          width: 65px;
          height: 65px;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
        }
      }
      &__imageInitials {
        width: 65px;
        height: 65px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__level {
        font-weight: 500;
        font-size: 15px;
        &.high {
          color: $high;
        }
        &.medium {
          color: $medium;
        }
        &.low {
          color: $low;
        }
        &.injured {
          color: $grayinjured;
        }
        &.contained {
          color: $yellowcont;
        }
      }
      &__name {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
      }

      &__lastUpdate {
        font-weight: 400;
        font-size: 15px;
        color: #ffffff;
        opacity: 0.5;
      }

      &__arrow {
        display: flex;
        align-items: center;
        img {
          transform: rotate(-90deg);
        }
      }
      &__label {
        display: flex;
        align-items: center;
        padding: 6px 15px;
        width: 216px;
        height: 30px;
        border: 1px solid #ffffff;
        border-radius: 10px;
        margin-right: 40px;
        justify-content: center;
        span {
          font-size: 15px;
          opacity: 0.5;
        }
      }
      &__mobileLabel {
        margin-right: 15px;
        span {
          font-size: 20px;
          opacity: 0.5;
        }
      }
    }

    .modified{
      position: absolute;
      top: 5px;
      right: 4px;
      width: 17px;
      height: 17px;
      border: 2px solid white;
      border-radius: 50%;
      &.high {
        background-color: $high;
      }
      &.medium {
        background-color: $medium;
      }
      &.low {
        background-color: $low;
      }
      &.injured {
        background-color: $grayinjured;
      }
      &.contained {
        background-color: $yellowcont;
      }
    }
  }
}
