@import '../../assets/scss/variables';

.accordion {
  cursor: pointer;
  border: none;
  max-height: 30px;
  position: absolute;
  right: 39px;
  top: 70px;

  img {
    width: 30px;
  }
}

.panel {
  border: none;
  position: relative;

  &.history {
    background-color: transparent;
    padding: 0 16px;
  }
}

.panel.active {
  max-height: 2500px;
}

.tooltip {
  height: 15px;
  width: 15px;
  margin-left: 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  background-color: $gray;
  border-radius: 50%;
  line-height: 15px;
}

.select {
  display: flex;
  padding: 10px 10px 0 10px;
  margin: 0 20px 0 20px;
  align-items: center;
  //justify-content: space-between;

  &__wrap {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  &__date {
    display: flex;
    flex-direction: column;

    &Label {
      margin-top: 10px;
      margin-left: 40px;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
    }

    &Input {
      width: 300px;
      padding: 3px 10px;
      border-radius: 18px;
      background-color: $black20;
      border: 2px solid $gray;
      color: $white;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      line-height: 16px;
      font-weight: lighter;
    }

    &View {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
      box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
      border-radius: 84px;
      font-size: 14px;
      cursor: pointer;
      width: 204px;
      height: 36px;
      max-width: 100%;
      &:active {
        background: #34393f;
      }
    }
  }
}

.playerSummary__items {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 14px;
}

.playerWrapper {
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
  padding: 31px 39px 31px 35px;
}

.playerData {
  &__date {
    margin: 26px 0 21px 55px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }
}

.player {
  display: flex;
  flex-direction: column;

  &__wrap {
    display: flex;
    align-items: center;
  }

  .player__list {
    display: flex;
    align-items: center;

    & .dot {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin-right: 10px;

      &.high {
        background: linear-gradient(180deg, #bb2628 0%, #b03b2a 100%);
      }

      &.medium {
        background: linear-gradient(180deg, #c66f29 0%, #cb8f29 100%);
      }

      &.contained {
        background: linear-gradient(180deg, #ecd858 0%, #e0d35a 100%);
      }
    }
  }

  ul {
    padding-inline-start: 10px;
    margin: 0 0 0 10px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }

  &__cont {
    padding-left: 33px;
  }

  & .dot {
    line-height: 38px;
    padding-left: 4px;
  }

  .dot,
  &__level {
    &.high {
      color: $high;
    }

    &.medium {
      color: #c66f29;
    }

    &.contained {
      color: $yellowhigh;
    }

    &.low {
      color: $low;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    width: 67px;
    height: 67px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 67px;
      height: 67px;
      object-fit: cover;
      border-radius: 50%;
    }

    &Initials {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      text-align: center;
      background-color: $gray;
      font-size: 16px;
      font-weight: lighter;
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      width: 100%;
      height: 100%;
      line-height: 20px;

      &.high {
        border-color: $high;
      }

      &.medium {
        border-color: $medium;
      }

      &.contained {
        border-color: $yellowhigh;
      }

      &.low {
        border-color: $low;
      }
    }
  }

  &__title {
    color: $white;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  &__level {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
    margin-top: 6px;
    line-height: 18px;
  }

  &__detected {
    display: flex;
    align-items: center;
    color: $white;
    margin-top: 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    &Title {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      width: 111px;
      margin-right: 10px;
    }

    &Date {
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
    }
  }
}

.riskManagement {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;

  &.active {
    margin-top: 40px;
    max-height: unset;

    &.high {
      &:before {
        background: $high;
      }
    }

    &.medium {
      &:before {
        background: #c66f29;
      }
    }

    &.contained {
      &:before {
        background: $yellowhigh;
      }
    }

    &.low {
      &:before {
        background: $low;
      }
    }

    &:before {
      width: 95%;
      height: 1px;
      content: '';
      display: flex;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 26px;
    }
  }

  &__title {
    display: inline-flex;
    flex-direction: column;
    width: 120px;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 16px;

    &:after {
      margin-top: 6px;
      width: 48px;
      display: flex;
      content: '';
      background: #ffffff;
      height: 1px;
    }
  }

  &__recommendations {
    display: flex;
    flex-direction: column;
    margin-top: 36px;

    &.showRecommendations {
      width: 100%;
    }
    &.noWorkload {
      display: flex;
      justify-content: center;
      padding: 16px;
      text-align: center;
    }
  }
}

.recommendationCategory {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  &:after {
    content: '';
    display: flex;
    width: 95%;
    align-self: center;
    margin-top: 16px;
    margin-bottom: 14px;
    height: 1px;
    background: rgba(255, 255, 255, 0.25);
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  &__name {
    display: flex;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 12px;
  }

  &__keys {
    display: flex;
    flex-direction: column;

    &.noWorkload {
      display: flex;
      justify-content: center;
      padding: 16px;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
    }
  }

  &__keysItem {
    display: flex;
    flex-grow: 1;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__key {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    width: 100%;

    & div {
      display: flex;
      width: 60%;
      font-weight: 400;
    }

    & span {
      display: flex;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      margin-left: 0;
      width: 40%;
      padding-left: 10px;
    }
    & div {
      display: inline;
    }
  }
}

@media (max-width: 1199px) {
  .panel {
    &.history {
      padding: 0;
    }
  }

  .playerSummary__items {
    grid-template-columns: 100%;
  }

  .select {
    margin: 0;
    padding-left: 0;
    padding-right: 0;

    input {
      width: 300px;
    }

    &__date {
      &Label {
        margin-left: 15px;
      }
    }
  }

  .playerData {
    &__date {
      margin: 26px 0 20px 15px;
    }
  }
}

@media (max-width: $screen-mobile) {
  .panel {
    &.history {
      padding: 0 14px 23px;
    }
  }

  .accordion {
    top: 17px;
    right: 20px;
  }

  .select {
    margin-top: 22px;
    padding: 0;

    &__dateLabel {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }

    &__date {
      max-width: 400px;
      flex: 1;
      min-width: 205px;
    }

    input {
      width: 100%;
    }

    &__dateView {
      flex: 1;
      max-width: 204px;
      min-width: 103px;
      margin-left: 19px;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .player {
    flex-direction: column;
    font-size: 17px;

    &__list {
      .dot {
        width: 8px !important;
        height: 8px !important;
        margin-right: 7px !important;
      }
    }

    &__cont {
      padding-left: 24px;
    }

    &__image {
      width: 49px;
      height: 49px;

      img {
        width: 49px;
        height: 49px;
      }
    }

    &Wrapper {
      padding: 18px 20px 26px 6px;
    }

    &Summary__items {
      margin-top: 14px;
    }

    &__wrap a {
      display: flex;
      flex-direction: row;
      padding-bottom: 20px;

      .player__list {
        & .dot {
          display: none;
        }
      }

      & ul {
        list-style-type: none;
        padding-inline-start: 0;
        margin: 0;
      }
    }

    &__details {
      flex-direction: column;
    }

    &__detected {
      font-size: 14px;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 22px;
      padding-left: 10px;

      &Title {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin: 0;
        width: 50px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 50px;
        bottom: 0;
        width: calc(100% - 100px);
      }

      &Date {
        margin-top: 0;
        margin-left: 24px;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &Data__date {
      margin: 0 0 0 28px;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .panel {
    min-width: 100%;
  }

  .player__name {
    padding: 10px;
  }

  .riskManagement {
    flex-direction: column;
    padding-left: 14px;

    &.active {
      margin-top: 27px;
    }

    &:before {
      margin-bottom: 24px !important;
    }

    &__title {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      margin-left: auto;
      margin-right: auto;
    }

    &__recommendations {
      margin-left: 0;
      margin-top: 27px;
    }

    .accordion {
      font-size: 17px;
    }
  }

  .recommendationCategory {
    padding: 0;
    font-size: 14px;
    width: 100%;

    &__name {
      min-width: 150px;
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 14px;
    }

    &__keys {
      flex-direction: column;
      margin-top: 0;
    }

    &__keysItem {
      flex-direction: row;
      max-width: 100%;
      margin-bottom: 16px;
    }

    &__key {
      font-size: 14px;
      width: 100%;

      div {
        font-size: 12px;
        line-height: 14px;
        width: 50%;
      }

      span {
        font-weight: 300;
        font-size: 12px;
        width: 50%;
        line-height: 14px;
      }
    }

    &__value {
      font-size: 14px;
    }
  }
}
