@import '../../assets/scss/variables';

.header {
  display: flex;
  color: #ffffff;
  padding: 30px 75px 20px 100px;
  width: 100%;
  background: linear-gradient(180deg, #34393F 0%, #18191D 751.39%);
  box-shadow: 0 4px 12px -1px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0;
  z-index: 100;
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  &__menu {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
  }

  &__content{
    display: flex;
    align-items: center;
  }

  &__last-update {
    font-size: 14px;
    line-height: 21px;
    opacity: 0.5;
    margin-left: 30px;
  }

  &__title {
    font-size: 36px;
    line-height: 42px;
    font-weight: 300;
  }

  &__info {
    &-image {
      width: 50px;
      height: 50px;

    }
  }

  &__info_collapsed {

  }
}

@media (max-width: 1199px) {
  .header {
    &__title {
      font-size: 25px;
    }
  }
}

@media (max-width: 767px) {
  .header {
    padding: 25px 40px 25px 65px;
    align-items: center;

    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__last-update {
      font-size: 12px;
      line-height: 14px;
      margin-top: 9px;
      margin-left: 0;
    }

    &__menu {
      flex-direction: column;
      align-items: flex-start;
    }

    &__info {
      &-image {
        width: 40px;
        height: 40px;
      }
    }
  }
}
