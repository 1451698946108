@import '../../../assets/scss/variables';

.panel {
  padding: 14px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: $black32;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.35);
}

.player {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0 55px 150px;

  &__blocksWrap {
    display: flex;
    flex-direction: row;

    &.bio {
      flex-direction: column;
    }
  }

  &__block1 {
    display: flex;
    flex-direction: column;
    //width: 50%;

    flex-grow: 1;
    min-width: 40%;

    &.bio {
      flex-direction: row;
      width: 100%;
    }

    &.noAdjustments,
    &.injured {
      width: 100%;
    }
  }

  &__block2 {
    display: flex;
    flex-grow: 1;
    //min-width: 48%;
    //max-width: 50%;

    min-width: 45%;

    &:not(.bio) {
      margin-left: 10px;
    }
  }
}

@media (max-width: $screen-tablet) {
  .player {
    width: 100%;
    padding: 0 35px;
  }
}

@media (max-width: $screen-mobile) {
  .player__tooltip {
    margin-left: 0;
    margin-right: 10px;
  }

  .player {
    padding: 10px;

    &__block1,
    &__block2,
    &__blocksWrap {
      flex-direction: column;
    }

    &__block1 {
      width: 100%;

      &.bio {
        flex-direction: column;
      }
    }

    &__block2 {
      margin-left: 0;
      max-width: 100%;

      &:not(.bio) {
        margin-left: 0;
      }
    }
  }
}
