@import "../../assets/scss/variables";

.accordion {
  cursor: pointer;
  border: none;
  max-height: 30px;
  position: absolute;
  right: 39px;
  top: 48px;

  img {
    width: 30px;
  }
}

.panel {
  border: none;
}

.panel.active {
  max-height: 2500px;
}

.teamReport {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 25px;

    &Wrap {
      display: flex;
      width: 100%;
      max-width: 710px;
      padding-left: 25px;
      font-size: 15px;
    }
  }

  &__date {
    min-width: calc(50% + 30px);
  }
  &__csvButton {
    display: flex;
    width: 160px;
    height: 36px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    border: none;
    cursor: pointer;
    font-weight: 400;
    background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 84px;
  }
}

.block {
  display: flex;
}

.players {
  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    margin-top: 26px;
    margin-left: 34px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    justify-content: space-between;
  }
}
.showAll {
  align-self: flex-end;
  font-size: 13px;
  font-weight: lighter;
  opacity: 0.5;
  cursor: pointer;
  text-transform: capitalize;
}

.asterisk {
  margin-left: 10px;
}

.players__container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 14px;
}

.playerWrapper {
  background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
  padding: 31px 39px 31px 35px;
  position: relative;
}

.risksInfo {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: 2500px;
    padding-top: 25px;

    &.high {
      &:before {
        background: $high;
      }
    }

    &.medium {
      &:before {
        background: #c66f29;
      }
    }

    &.contained {
      &:before {
        background: $yellowhigh;
      }
    }

    &.low {
      &:before {
        background: $low;
      }
    }

    &:before {
      width: 95%;
      height: 1px;
      content: "";
      display: flex;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 26px;
    }
  }
}

.risks {
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__content {
    display: flex;
    width: 100%;
  }
}

.player {
  display: flex;
  flex-direction: column;

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__lastUpdate {
    font-weight: 400;
    font-size: 15px;
    color: #ffffff;
    opacity: 0.5;
  }

  .player__list {
    display: flex;
    align-items: center;

    & .modified {
      position: absolute;
      top: 4px;
      right: 2px;
      width: 15px;
      height: 15px;
      border: 1px solid white;
      border-radius: 50%;

      &.high {
        background-color: $high;
      }
      &.medium {
        background-color: $medium;
      }
      &.low {
        background-color: $low;
      }
      &.injured {
        background-color: $grayinjured;
      }
      &.contained {
        background-color: $yellowcont;
      }
    }
  }

  ul {
    padding-inline-start: 10px;
    margin: 0 0 0 10px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }

  &__cont {
    padding-left: 33px;
  }

  & .dot {
    line-height: 38px;
    padding-left: 4px;
  }

  .dot,
  &__level {
    &.high {
      color: $high;
    }

    &.medium {
      color: #c66f29;
    }

    &.contained {
      color: $yellowhigh;
    }

    &.low {
      color: $low;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    width: 67px;
    height: 67px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 67px;
      height: 67px;
      object-fit: cover;
      border-radius: 50%;
    }

    &Initials {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      text-align: center;
      background-color: $gray;
      font-size: 16px;
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      width: 100%;
      height: 100%;
      line-height: 20px;

      &.high {
        border-color: $high;
      }

      &.medium {
        border-color: $medium;
      }

      &.contained {
        border-color: $yellowhigh;
      }

      &.low {
        border-color: $low;
      }
    }
  }

  &__title {
    color: $white;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  &__level {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
    margin-top: 6px;
    line-height: 18px;
  }

  &__detected {
    display: flex;
    align-items: center;
    color: $white;
    margin-top: 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    &Title {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      width: 111px;
      margin-left: 0;
      margin-right: 10px;
    }

    &Date {
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
    }
  }
}

.riskManagement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__content {
    width: 100%;

    &:not(.show) {
      display: none;
    }
  }

  &.active {
    margin-top: 40px;
    max-height: unset;

    &.high {
      &:before {
        background: $high;
      }
    }

    &.medium {
      &:before {
        background: #c66f29;
      }
    }

    &.contained {
      &:before {
        background: $yellowhigh;
      }
    }

    &.low {
      &:before {
        background: $low;
      }
    }

    &:before {
      width: 95%;
      height: 1px;
      content: "";
      display: flex;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 26px;
    }
  }

  &__title {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.5;
    cursor: pointer;

    &.show {
      opacity: 1;
    }

    &:after {
      margin-top: 6px;
      width: 48px;
      display: flex;
      content: "";
      background: #ffffff;
      height: 1px;
    }
  }

  &__recommendations {
    display: flex;
    flex-direction: column;
    margin-top: 36px;

    &.showRecommendations {
      width: 100%;
    }

    &.noWorkload {
      display: flex;
      justify-content: center;
      padding: 16px;
      text-align: center;
    }
  }
}

.riskFactors {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__content {
    width: 100%;

    &:not(.show) {
      display: none;
    }
  }

  &__title {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    margin-left: 44px;
    opacity: 0.5;
    cursor: pointer;

    &.show {
      opacity: 1;
    }

    &:after {
      margin-top: 6px;
      width: 48px;
      content: "";
      background: #ffffff;
      height: 1px;
      display: flex;
    }
  }

  &__contributions {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    margin-top: 36px;

    &Workload,
    &Nonworkload {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.contributions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &__name {
    display: flex;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  &__value {
    padding-top: 47px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 100px;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    &Wrapper {
      display: flex;
      justify-content: center;
    }

    img {
      width: 30px;
      height: 30px;
    }

    span {
      margin-top: 14px;
    }
  }
}

.recommendationCategory {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 19px 0;
  position: relative;

  &:after {
    content: "";
    display: flex;
    position: absolute;
    height: 1px;
    width: calc(100% - 26px);
    right: 15px;
    bottom: -19px;
    background: rgba(255, 255, 255, 0.25);
  }

  &:last-child {
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &__name {
    display: flex;
    text-transform: capitalize;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  &__keys {
    display: flex;
    flex-direction: column;

    &.noWorkload {
      display: flex;
      justify-content: center;
      padding: 16px;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
    }
  }

  &__keysItem {
    display: flex;
    width: 100%;

    &:last-child {
      .recommendationCategory__key {
        padding-bottom: 0;
      }
    }
  }

  &__key {
    display: flex;
    width: 100%;
    padding-bottom: 12px;

    div {
      display: flex;
      width: 60%;
      padding-right: 10px;

      span {
        display: flex;
        width: 200px;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
      }
    }

    & span {
      display: flex;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color: #fff;
      width: 40%;
      margin-left: 0;
    }
  }
}

.reference {
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  padding-left: 35px;

  &__title {
    padding: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  &__dates {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-left: 12px;
    padding-bottom: 25px;
  }

  &__date {
    min-width: 152px;
    height: 49px;
    padding-left: 17px;
    padding-right: 17px;
    margin-right: 16px;
    margin-top: 11px;
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    background: linear-gradient(180deg, #2c2f35 0%, #2f3539 100%);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 11px;

    &Active {
      cursor: pointer;
    }
  }
}

.recommended {
  display: flex;
  flex-direction: column;

  &__total {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 18px;
  }

  &__date {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 12px;
  }

  &__drills {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
    padding: 0 30px;
  }

  &__drill {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 17px 0;

    &:first-child {
      border-bottom: none;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      padding-bottom: 8px;
    }
  }

  &__name {
    width: 70%;
  }

  &__duration {
    width: 30%;
  }
}

.textTransformUppercase {
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .players__container {
    grid-template-columns: 100%;
  }

  .playerData {
    &__date {
      margin: 26px 0 20px 15px;
    }
  }
}

@media (max-width: $screen-mobile) {
  .panel {
    min-width: 100%;
  }

  .teamReport__headWrap {
    padding-left: 10px;
  }

  .recommended {
    padding: 0 7px;

    &__drills {
      margin-top: 10px;
      padding: 0;
    }
  }

  .contributions {
    &__value {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      padding-top: 17px;
      padding-bottom: 8px;
      height: auto;
    }
  }

  .block {
    flex-direction: column;
  }

  .risksInfo {
    padding-left: 16px;
  }

  .teamReport {
    &__headWrap {
      flex-direction: column;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      width: 100%;
      min-width: unset;
    }

    &__date {
      margin-bottom: 12px;
    }

    &__csvButton {
      width: 120px;
      font-size: 12px;
    }

    &__print {
      font-size: 12px;
      margin-left: 10px;
      height: 32px;
      min-width: 110px;
      padding: 5px 10px 5px 10px;

      & img {
        margin-right: 20px;
      }
    }
  }

  .accordion {
    top: 17px;
    right: 20px;
  }

  .player {
    flex-direction: column;
    font-size: 17px;

    & .dot {
      width: 8px !important;
      height: 8px !important;
      margin-right: 7px !important;
    }

    &__cont {
      padding-left: 24px;
    }

    &__image {
      width: 49px;
      height: 49px;

      img {
        width: 49px;
        height: 49px;
      }
    }

    &Wrapper {
      padding: 18px 20px 26px 6px;
    }

    &Summary__items {
      margin-top: 14px;
    }

    &__wrap a {
      display: flex;
      flex-direction: column;

      .player__list {
        & .dot {
          display: none;
        }
      }

      & ul {
        list-style-type: none;
        padding-inline-start: 0;
        margin: 0;
      }
    }

    &__details {
      flex-direction: column;
    }

    &__detected {
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 15px;
      padding: 0 10px;

      &Title {
        font-weight: 500;
        width: 50px;
        font-size: 12px;
        line-height: 14px;
      }

      &:after {
        content: "";
        position: absolute;
        left: 50px;
        bottom: 0;
        width: calc(100% - 100px);
      }

      &Date {
        margin-top: 0;
        margin-left: 24px;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &Data__date {
      margin: 0 0 0 28px;
      font-size: 15px;
      line-height: 18px;
    }

    .player__list {
      & .modified {
        top: 2px;
        right: 1px;
        width: 12px;
        height: 12px;
      }
    }
  }

  .riskFactors {
    &__content {
      width: 100%;

      &:not(.show) {
        display: none;
      }
    }

    &__title {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      //margin-left: auto;
      //margin-right: auto;
      opacity: 0.5;

      &.show {
        opacity: 1;
      }

      &:after {
        display: flex;
        margin-top: 8px;
      }
    }
  }

  .riskManagement {
    flex-direction: column;

    &__content {
      width: 100%;

      &:not(.show) {
        display: none;
      }
    }

    &.active {
      margin-top: 27px;
    }

    &:before {
      margin-bottom: 24px !important;
    }

    &__title {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      //margin-left: auto;
      //margin-right: auto;
      opacity: 0.5;

      &.show {
        opacity: 1;
      }

      &:after {
        display: flex;
        margin-top: 8px;
      }
    }

    &__recommendations {
      margin-left: 0;
      margin-top: 27px;
    }

    .accordion {
      font-size: 17px;
    }
  }

  .players {
    &__title {
      font-size: 15px;
      line-height: 18px;
      margin-left: 20px;
      margin-top: 24px;
      margin-bottom: 14px;
    }
  }

  .reference {
    margin-top: 14px;
    background: linear-gradient(
      180deg,
      rgba(44, 47, 53, 0.5) 0%,
      rgba(47, 53, 57, 0.5) 100%
    );
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 11px;
    padding: 17px 12px 18px;

    &__dates {
      padding: 0;
      margin-top: 16px;
    }

    &__date {
      margin-right: 0;
      margin-top: 11px;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;

      &:first-child {
        margin-top: 0;
      }
    }

    &__title {
      font-size: 15px;
      line-height: 18px;
      font-weight: normal;
      margin-left: 10px;
    }
  }

  .recommendationCategory {
    padding: 0;
    font-size: 14px;
    width: 100%;

    &__name {
      min-width: 150px;
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 14px;
    }

    &__keys {
      flex-direction: column;
      margin-top: 0;
    }

    &__keysItem {
      flex-direction: row;
      max-width: 100%;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__key {
      font-size: 12px;
      width: 100%;
      padding-bottom: 0;

      div {
        font-size: 12px;
        line-height: 14px;
        width: 50%;

        span {
          font-size: 12px;
          line-height: 14px;
        }
      }

      span {
        font-weight: 300;
        font-size: 12px;
        width: 50%;
        line-height: 14px;
      }
    }

    &__value {
      font-size: 14px;
    }
  }
}
