@import '../../assets/scss/_variables.scss';

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #34393f 0%, #18191d 751.39%);
  font-weight: normal;

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 135px;

    img {
      width: 265px;
      margin-bottom: 12px;
    }
  }

  .loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .loginButton {
    display: flex;
    margin-top: 162px;
    padding: 0;
    width: 204px;
    height: 36px;
    font-size: 14px;
    line-height: 16px;
    border: none;
    font-style: normal;
    font-weight: normal;
    background: transparent;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
    border-radius: 84px;
    color: $white;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    span {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-image: -webkit-gradient(linear, left top, left bottom, from(#2c2f35), to(#1f2326));
      background-image: -o-linear-gradient(top, #2c2f35 0%, #1f2326 100%);
      background-image: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
      border-radius: 84px;

      &:active {
        background: #34393f;
        opacity: 1;
      }
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;

    & form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: lighter;
      position: relative;
    }

    & .email {
      width: 100%;
      margin-bottom: 22px;

      input {
        text-decoration: underline;

        &:placeholder-shown {
          font-weight: normal;
          text-decoration: underline;
          text-decoration-color: rgba(251, 175, 93, 0);
        }
      }
    }

    input {
      width: 325px;
      height: 47px;
      border: 1px solid $white;
      border-radius: 84px;
      padding: 0 25px;
      font-size: 14px;
      line-height: 16px;
      background-color: $gray8e;
      color: $white;
      font-weight: normal;
      max-width: 100%;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $gray8e inset !important;
        -webkit-text-fill-color: $white !important;
        font-family: 'Roboto', sans-serif;
      }
    }

    & .password input {
      letter-spacing: 3px;
      color: #c4c4c4;

      &::placeholder {
        letter-spacing: 0;
      }
    }

    & .password {
      width: 100%;
    }

    .forgot {
      margin-top: 22px;
      margin-bottom: 40px;
      font-size: 12px;
      line-height: 14px;
      color: $white;

      a {
        color: #0070f3;
        transition: 0.3s ease-in-out;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .error {
      position: absolute;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #ff4747;
      max-width: 235px;
      width: 235px;
      left: 50%;
      transform: translateX(-50%);
      top: -67px;
    }
  }
}

@media (max-width: $screen-tablet) {
  .container {
    .logo {
      margin-bottom: 105px;

      img {
        margin-bottom: 22px;
      }
    }

    .loginButton {
      margin-top: 122px;
    }
  }
}

@media (max-width: $screen-mobile) {
  .container {
    padding-top: 97px;

    .login {
      input {
        max-width: 100%;
        font-size: 12px;
        line-height: 14px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          font-size: 12px;
          line-height: 14px;
          font-family: 'Roboto', sans-serif;
        }
      }
    }
  }
}
