@import '../../../assets/scss/variables';

.select {
  display: flex;
  padding: 10px 50px 10px 0;
  align-items: center;
  justify-content: space-between;

  &__date {
    display: flex;
    flex-direction: column;
    padding-left: 24px;

    &Label {
      margin-top: 24px;
      margin-left: 34px;
    }

    &View {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      background: linear-gradient(180deg, #2c2f35 0%, #1f2326 100%);
      box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
      border-radius: 84px;
      width: 204px;
      height: 36px;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
      color: #ffffff;

      &:active {
        background: #34393f;
      }
    }
  }
}

@media (max-width: $screen-tablet) {
}

@media (max-width: $screen-mobile) {
  .select {
    margin-left: 0;
    padding: 10px 0;

    &__date {
      padding-left: 0;
    }

    &__dateLabel {
      font-size: 14px;
      margin-left: 10px;
    }

    &__dateView {
      font-size: 12px;
      margin-left: 20px;
      height: 32px;
      line-height: 24px;
    }
  }
}
